import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import Loader from "../../../components/Loader";
import AvatarSelection from "../components/AvatarSelection";
import AccentSelection from "../components/AccentSelection";
import ModeSelection from "../components/ModeSelection";
import InstructionVideo from "../components/InstructionVideo";

const ChooseConfigurationContent = ({
  content,
  videoRef,
  loader,
  setLoader,
  selectedAvatar='Jonathan',
  handleCardClick,
  onChange,
  value,
  setVoiceMode,
}) => {
  return (
    <div className="animate-fade-in-1s flex flex-col justify-center items-center py-12">
      {loader && (
        <div className="fixed inset-0 backdrop-blur-sm z-20">
          <Loader />
        </div>
      )}

      <div className="flex flex-col lg:flex-row justify-center items-center w-full max-w-6xl mx-auto">
        <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
          <InstructionVideo
            videoSrc={content?.video}
            videoRef={videoRef}
            setLoader={setLoader}
          />
        </div>
        <div className="w-full lg:w-3/4 lg:pl-6 overflow-y-auto max-h-[70vh] lg:max-h-[80vh]">
          <div className="">
            <h5 className="px-5 text-2xl font-bold tracking-tight text-gray-900">
              {content?.title}
            </h5>
            <AvatarSelection
              avatars={content?.options?.avatars}
              handleCardClick={handleCardClick}
              selectedAvatar={selectedAvatar}
            />
            <AccentSelection
              onChange={onChange}
              value={value}
              accents={content?.options?.accents}
            />
            <ModeSelection
              modes={content?.options?.modes}
              setVoiceMode={setVoiceMode}
            />
            <div className="w-full">
              <Link
                className="inline-flex items-center w-full justify-center"
                to="/"
              >
                <button className="flex items-center gap-4 bg-primary text-white font-bold px-4 py-2 rounded justify-center text-center">
                  {content?.button} <FaArrowRight />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseConfigurationContent;
