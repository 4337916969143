import React, { useContext, useEffect } from "react";
import Answer from "./Answer";
import { MessageBox } from "../components";
import { Button } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import AvatarContext from "./AvatarContext";
import AnswerNotFound from "./NotFound";
import annyang from "annyang";
import { getScript } from "../utils/handleRequest";
import { useSelector } from "react-redux";
const Description = () => {
  const {
    setGreetingVideo,
    setPersonalizedQuest,
    setConversation,
    showMSL,
    showFollowupQuestion,
    setListening,
    response,
    setVideoSilent,
    setAssistantQN,
  } = useContext(AvatarContext);
  const user = useSelector((state) => state.auth.user);
  const [content, setContent] = React.useState(null);

  useEffect(() => {
    async function fetchScript() {
      if (showMSL) {
        const content = await getScript("answerNotFound", user.brand);
        setContent(content.data);
      } else {
        const content = await getScript("conversationScreen", user.brand);
        setContent(content.data);
      }
    }
    fetchScript();
  }, [showMSL]);

  function backButton() {
    setConversation(false);
    setGreetingVideo(null);
    setPersonalizedQuest(null);
    setAssistantQN("");
    setVideoSilent(true);

    if (annyang) annyang.abort();
    setListening(false);
  }

  return (
    <div className="md:w-[65%] w-full flex flex-col items-center">
      {/* have answer */}
      <Button
        data-intro="Click this button to return to the home."
        data-position="left"
        type="text"
        style={{ margin: "1rem", alignSelf: "flex-end", position: "absolute" }}
        onClick={() => {
          backButton();
        }}
        className={`${showMSL ? "hidden" : "block"} border border-gray-300`}
      >
        {content?.buttons.find((b) => b.key === "backButton").text}
      </Button>
      {showMSL ? (
        <AnswerNotFound options={content} />
      ) : (
        <Answer options={content?.buttons} />
      )}

      {/* dont have answer */}
      {showFollowupQuestion && response.followup_question && (
        <div
          key={response.followup_question}
          data-intro="follow up question."
          className="flex gap-2 bg-white rounded-lg my-2 md:w-[28rem] w-[85%] animate-fade-in-1s"
        >
          <AiOutlineQuestionCircle className="h-5 w-5 text-gray-600" />
          <div className="text-sm flex-1">{response.followup_question}</div>
        </div>
      )}

      <div className="w-full md:max-w-[40%] mb-28 md:mb-15">
        <MessageBox />
      </div>
    </div>
  );
};

export default Description;
