import React from "react";
import { Link, useLocation } from "react-router-dom";

const UnAuthorized = () => {
  const location = useLocation();
  const brand = location.state;
  return (
    <div className="flex justify-center items-center flex-col border border-gray-300 rounded-lg bg-gray-100 h-screen gap-4 items-center">
      <h1>403 - Unauthorized Access</h1>
      <p className="text-lg">
        You do not have permission to view this page using the credentials you
        provided.
      </p>
      {/* <Link
        to={`/pharma/${brand?.brandName}/signin`}
        className="text-blue-500 text-base"
      >
        Go to {brand?.brandName} Login Page
      </Link> */}
    </div>
  );
};

export default UnAuthorized;
