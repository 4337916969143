import React from 'react';
import { Modal } from 'antd';
import QRCode from "react-qr-code";
import { toast } from 'sonner';

const ShareModal = ({ shareLink, open, onCancel }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <div className="flex items-center justify-center">
        <div className="w-full max-w-md p-6 text-center bg-white rounded-lg">
          <div className="mb-4">
          <QrCodeIcon className="w-10 h-10 mx-auto mb-2 text-primary" />
          <h2 className="text-xl font-semibold">Share Reference Link</h2>
          <p className="text-sm text-gray-600">Scan the QR to create a new account.</p>
        </div>
        <div className="flex justify-center mb-4">
        <div className="mx-auto w-full max-w-[150px]">
            <QRCode
                size={256}
                className="w-full h-auto"
                value={shareLink}
                viewBox={`0 0 256 256`}
            />
        </div>
        </div>
        <p className="text-sm text-gray-500">OR enter the code manually</p>
        <div className="flex items-center justify-center mt-4 space-x-2">
          <input
            type="text"
            value={shareLink?.split("?")[0]+'...'}
            readOnly
            className="flex-1 px-4 py-2 text-center border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <button className="p-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary"
          onClick={() => {
            navigator.clipboard.writeText(shareLink);
              toast.success("Copied to clipboard");
          }}
          >
            <CopyIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
      </div>
    </Modal>
  );
};

function CopyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
    </svg>
  );
}

function QrCodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="5" height="5" x="3" y="3" rx="1" />
      <rect width="5" height="5" x="16" y="3" rx="1" />
      <rect width="5" height="5" x="3" y="16" rx="1" />
      <path d="M21 16h-3a2 2 0 0 0-2 2v3" />
      <path d="M21 21v.01" />
      <path d="M12 7v3a2 2 0 0 1-2 2H7" />
      <path d="M3 12h.01" />
      <path d="M12 3h.01" />
      <path d="M12 16v.01" />
      <path d="M16 12h1" />
      <path d="M21 12v.01" />
      <path d="M12 21v-1" />
    </svg>
  );
}

export default ShareModal;