import { useSelector } from "react-redux";

const AnsweredQuestions = ({ question }) => {
  const { bgColor } = useSelector((state) => state.colors);

  return (
    <div className="my-5 flex flex-col gap-y-3">
      <div className="w-fit">
        <h1 className="text-xl font-semibold">
          Answered questions ({question?.length})
        </h1>
        <p className={`${bgColor} h-0.5 w-3/4 rounded-lg`}></p>
      </div>
      <div className="flex items-center flex-wrap gap-x-4 gap-y-3 p-4 rounded-lg border border-gray-300">
        {question?.length === 0 ? (
          <div className="w-full flex justify-center">
            <p className="text-gray-500 text-center">No Questions Asked.</p>
          </div>
        ) : (
          question?.map((que, i) => {
            return (
              <div
                key={i}
                className="bg-gray-100 rounded-lg p-3 border border-gray-200 w-[32%]  flex flex-col gap-y-2"
              >
                <p>{que}</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AnsweredQuestions;
