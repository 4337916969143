import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BiSearch } from "react-icons/bi";
import InviteModal from "./InviteModal";
import { getDashboardUserDetailsMetrics } from "../redux/actions/auth";
import { toast } from "sonner";

function customNoDataOverlay() {
  return (
    <div className="w-full py-4 flex flex-col items-center justify-center space-y-3 h-full">
      <BiSearch className="w-10 h-10 text-gray-400" />
      <p className="text-lg font-semibold text-gray-700">
        There is no data to show
      </p>
    </div>
  );
}

export const HCPTable = (props) => {
  // const { getAllInventory, inventoryData } = props;
  const { admin } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [spinning, setSpinning] = useState(true);

  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    if (!admin?.brand?.id) return;

    const fetchMetrics = async () => {
      try {
        const response = await getDashboardUserDetailsMetrics(admin?.brand?.id);
        const { data } = response;

        if (data.success) {
          const inventoryData = data.data.map((user, index) => {
            return {
              id: user.userId,
              name: user.name,
              email: user.email,
              status: user.inviteAccepted ? (
                <p className="text-green-500 bg-green-50 px-3 py-1 rounded-full">
                  Joined
                </p>
              ) : (
                <p className="text-yellow-500 bg-yellow-50 px-3 py-1 rounded-full">
                  Invited
                </p>
              ),
              questions: user.questionsAsked,
              unanswered: user.unansweredQuestions,
            };
          });
          setInventoryData(inventoryData);
        }
      } catch (error) {
        toast.error("Error in getting user details");
      } finally {
        setSpinning(false);
      }
    };

    fetchMetrics();
  }, [admin]);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center w-[90%] px-3">
            <div className="font-semibold text-[13.5px] text-gray-600 truncate">
              {params.row?.name}
            </div>
          </div>
        );
      },
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center w-[90%] px-3">
            <div className="text-[13.5px] text-gray-600 truncate">
              {params.row?.email}
            </div>
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center w-[90%] px-3">
            <div className={"truncate capitalize"}>{params.row?.status}</div>
          </div>
        );
      },
    },
    {
      field: "Questions asked",
      headerName: "Questions asked",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] text-[#667085] text-[13.5px] capitalize px-3">
            {params.row?.questions}
          </div>
        );
      },
    },
    {
      field: "Unanswered questions",
      headerName: "Unanswered questions",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="font-[400] text-[#667085] text-[13.5px] text-[#000000de] capitalize px-3">
            {params.row?.unanswered}
          </div>
        );
      },
    },
    {
      field: "Profile",
      headerName: "Profile",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            className="font-[400] text-blue-600 text-[13.5px] capitalize px-3 cursor-pointer"
            onClick={() => {
              navigate(`/pharma/${admin?.brand?.brandName}/doctor-profile`, {
                state: {
                  id: params.row?.id,
                  name: params.row?.name,
                  email: params.row?.email,
                  questions: params.row?.questions,
                  unanswered: params.row?.unanswered,
                },
              });

              window.scrollTo(0, 0);
            }}
          >
            <p className="underline">view</p>
          </div>
        );
      },
    },
  ];
  return (
    <div className="w-full fade-in font-Inter">
      {showInviteModal && (
        <InviteModal handleClose={() => setShowInviteModal(false)} />
      )}
      <div className="w-full pb-1.5 border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex items-center p-3 justify-between bg-gray-50 border-b border-b-gray-200">
          <h1 className="title">HCPs</h1>
          {/* <button
            className={`flex ${textColor} items-center bg-white rounded-lg shadow-sm px-3 py-1 gap-x-2 hover:shadow-md border border-gray-200`}
            onClick={() => setShowInviteModal(true)}
          >
            <RiUserAddLine /> invite
          </button> */}
        </div>
        <div className="h-[25rem]">
          <DataGrid
            sx={{
              overflowX: "scroll",
              border: 0,
              "& .css-17jjc08-MuiDataGrid-footerContainer": {
                borderTop: 0,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "700",
                fontSize: "13px",
                lineHeight: "18px",
                fontFamily: "Figtree, sans-serif",
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-selectedRowCount": {
                visibility: "hidden",
              },
              "& .css-17jjc08-MuiDataGrid-footerContainer": {
                borderTop: 0,
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.15rem",
              },
              "& .MuiDataGrid-columnHeader": {
                paddingLeft: "10px",
                backgroundColor: "#F9FAFB",
              },
              "& .MuiDataGrid-cell": {
                padding: "0px",
                fontFamily: "Figtree, sans-serif",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: "#FFFFFF",
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: "#F3F4F6",
              },
            }}
            rows={inventoryData}
            columns={columns}
            loading={spinning}
            getRowId={(row) => row?.id || Math.random()}
            slots={{
              noRowsOverlay: customNoDataOverlay,
              noResultsOverlay: customNoDataOverlay,
            }}
          />
        </div>
      </div>
    </div>
  );
};
