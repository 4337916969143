import React from "react";
import { Checkbox } from "antd";

const ModeSelection = ({ modes, setVoiceMode }) => {
  return (
    <div className="mt-4 px-5 self-start h-1/8">
      <p className="font-normal text-gray-600 dark:text-gray-400">
        {modes?.title}
      </p>
      <div className="mt-4">
        {modes?.options?.map((mode) => (
          <Checkbox
            key={mode?.value}
            defaultChecked={mode?.defaultChecked}
            className="mr-8"
            onChange={(e) => setVoiceMode(e.target.checked)}
          >
            {mode?.label}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default ModeSelection;
