import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import axios from "axios";
import { setCookie } from "../../../utils/cookie";
import ValidatorUtils from "../../../utils/validatorUtils";
import TermsOfuseModal from "../../../components/TermsOfUseModal";
import log from "../../../utils/log";
import { updateUser } from "../../../utils/handleRequest";

const LoginForm = () => {
  const validatorUtils = new ValidatorUtils();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [tokenDetails, setTokenDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [isPublicLocation, setIsPublicLocation] = useState(false);
  const [termsOfuseModal, setTermsOfuseModal] = useState(false);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const login = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/login`,
        { ...loginData, isPublicLocation }
      );
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/user-info`,
        {
          headers: {
            authorization: `Bearer ${res.data.data.accessToken}`,
          },
        }
      );
      const tokenDetails = {
        accessToken: res.data.data.accessToken,
        refreshToken: res.data.data.refreshToken,
        jwtExpiry: res.data.data.jwtExpiry,
      };
      setTokenDetails(tokenDetails);

      setUserDetails(resp.data.user);
      if (resp.data.user.termsOfUseAccepted) {
        setCookie("accessToken", tokenDetails.accessToken, tokenDetails.expiry);
        setCookie(
          "refreshToken",
          tokenDetails.refreshToken,
          tokenDetails.expiry
        );
        await verifyAndLoginUser(resp.data.user);
      } else {
        setTermsOfuseModal(true);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!validatorUtils.isEmailValid(loginData.email)) {
        return toast.error("Email is not valid!");
      }
      if (loginData.password.trim().length < 1) {
        return toast.error("Password is required!");
      }
      await login();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const verifyAndLoginUser = async (userDetails) => {
    try {
      dispatch({ type: "SET_USER", payload: userDetails });
      await dispatch({ type: "SET_PERSONALIZED_VIDEO", payload: true });
      navigate("/");
    } catch (error) {
      throw new Error(error);
    }
  };

  const termsAccepted = async () => {
    try {
      setCookie("accessToken", tokenDetails.accessToken, tokenDetails.expiry);
      setCookie("refreshToken", tokenDetails.refreshToken, tokenDetails.expiry);
      const updatedUser = await updateUser({
        termsOfUseAccepted: true,
        inviteAccepted: true,
      });
      if (!updatedUser.success) {
        throw new Error("Error in updating user");
      }
      setTermsOfuseModal(false);
      await verifyAndLoginUser(userDetails);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  if (termsOfuseModal) {
    return <TermsOfuseModal onAccept={termsAccepted} />;
  }

  return (
    <form onSubmit={handleSubmit} className="px-7 sm:px-0">
      <div className="mt-5">
        <label htmlFor="email" className="text-[#4E5674] font-[500] font-Inter">
          Email *
        </label>
        <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-2 px-3">
          <input
            className="border-none outline-none bg-transparent w-full font-Inter"
            type="text"
            name="email"
            id="email"
            required
            onChange={handleInputs}
            placeholder="Enter your email"
          />
        </div>
      </div>

      <div className="mt-4">
        <label
          htmlFor="password"
          className="text-[#4E5674] font-[500] font-Inter"
        >
          Password *
        </label>
        <div className="mt-1 flex items-center rounded-lg border-2 py-2 px-3">
          <input
            className="border-none outline-none bg-transparent w-full font-Inter"
            type="password"
            name="password"
            id="password"
            required
            onChange={handleInputs}
            placeholder="Enter your Password"
          />
        </div>
      </div>

      <div className="flex mt-1">
        <p
          onClick={() => {
            navigate("/forgot-password");
          }}
          className="font-[500] text-primary cursor-pointer font-Inter text-sm"
        >
          Forgot password
        </p>
      </div>
      <div className="flex justify-start mt-6">
        <input
          value={isPublicLocation}
          type="checkbox"
          className="w-3 h-3 mt-1 text-[#031927] bg-gray-100 accent-gray-100"
          onChange={(e) => setIsPublicLocation(e.target.checked)}
        />
        <p className="text-[##2970FF] font-Plus text-sm font-[500] ml-2">
          I'm in a public location
        </p>
      </div>

      {loading ? (
        <button
          disabled
          type="button"
          className="font-base mt-6 mb-2 block w-full rounded-lg bg-primary py-2.5 text-white"
        >
          <svg
            role="status"
            className="inline mr-3 w-4 h-4 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          Loading...
        </button>
      ) : (
        <button
          type="submit"
          className="font-semibold font-Inter text-sm mt-6 mb-2 block w-full rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary py-2.5 text-white"
        >
          Sign in
        </button>
      )}
    </form>
  );
};

export default LoginForm;
