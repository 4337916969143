// utils/icons.js
import React from "react";
import {
  MdOutlineSettings,
  MdOutlineTour,
  MdOutlineEdit,
  MdOutlinePolicy,
} from "react-icons/md";
import { BiCloset } from "react-icons/bi";
import { GiMedicines } from "react-icons/gi";
import { AiOutlineMedicineBox, AiOutlineSafety } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { MdOutlineShare } from "react-icons/md";
export const getIcon = (iconName) => {
  switch (iconName) {
    case "MdOutlineSettings":
      return <MdOutlineSettings className="text-gray-700 w-4 h-4" />;
    case "BiCloset":
      return <BiCloset className="text-gray-700 w-4 h-4" />;
    case "GiMedicines":
      return <GiMedicines className="text-gray-700 w-4 h-4" />;
    case "AiOutlineMedicineBox":
      return <AiOutlineMedicineBox className="text-gray-700 w-4 h-4" />;
    case "TbWorld":
      return <TbWorld className="text-gray-700 w-4 h-4" />;
    case "AiOutlineSafety":
      return <AiOutlineSafety className="text-gray-700 w-4 h-4" />;
    case "MdOutlineTour":
      return <MdOutlineTour className="text-gray-700 w-4 h-4" />;
    case "LuLogOut":
      return <LuLogOut className="text-red-500 w-4 h-4" />;
    case "IoClose":
      return <IoClose className="text-red-500 w-4 h-4" />;
    case "MdOutlinePolicy":
      return <MdOutlinePolicy className="text-gray-700 w-4 h-4" />;
    case "MdOutlineEdit":
      return <MdOutlineEdit className="text-gray-700 w-4 h-4" />;
    case "MdOutlineShare":
      return <MdOutlineShare className="text-gray-700 w-4 h-4" />;
    default:
      return null;
  }
};
