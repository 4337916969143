import PrivateRoute from "./components/PrivateRoute";

import {
  Authentication,
  ForgotPassword,
  ResetPassword,
  LoginCreation,
  CreatePassword,
} from "./pages/HCPAuthentication";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "sonner";
import React from "react";
import { Welcome } from "./pages/Welcome";
import { Configuration } from "./pages/Configuration";
import { Home } from "./pages/Home";
import Conversation from "./pages/Conversation";
import Settings from "./pages/Settings";
import HelpMenu from "./pages/HelpMenu";
import AssistentDemo from "./components/AssistentDemo";
import Index from "./pages/Index";
import PharmaAuthentication from "pages/PharmaAuthentication/PharmaAuthentication";
import NotFound from "./pages/NotFound";
import BrandNotFound from "./pages/BrandNotFound";
import { UnAuthorized } from "./pages/UnAuthorized";
import Invitation from "./pages/Invitation/Invitation";


const App = () => {
  return (
    <>
      <Toaster expand position="top-right" richColors closeButton />

      <Routes>
        <Route path="/signin" element={<Authentication />} />
        <Route path="/demo" element={<AssistentDemo />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/login-creation" element={<LoginCreation />} />
        <Route path="/invitation" element={<Invitation />} />
        {/* <Route path="/enter-secret" element={<EnterSecret />} /> */}

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/brand/:brandName" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/:brand/welcome" element={<Welcome />} />

          <Route path="/configuration" element={<Configuration />} />
          <Route path="/conversation" element={<Conversation />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<HelpMenu />} />
        </Route>

        {/* Pharma Routes */}
        <Route path="pharma/" element={<BrandNotFound />} />
        <Route path="pharma/signin" element={<BrandNotFound />} />
        <Route path="pharma/:brand/*" element={<Index />} />
        <Route path="pharma/signup/" element={<BrandNotFound />} />
        <Route path="pharma/unauthorized" element={<UnAuthorized />} />
        <Route
          path="pharma/:brand/signin/"
          element={<PharmaAuthentication />}
        />

        <Route path="*" element={<NotFound />} />

        {/* <Route path="/signup" element={<Authentication />} /> */}
        {/* Dashboard Routes */}
      </Routes>
    </>
  );
};

export default App;
