import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";  
import { Modal } from "antd";
import { MdHealthAndSafety } from "react-icons/md";
import { clearCookie } from "utils/cookie";
import { fetchContent } from "content/FetchContent";

const ISIModal = ({ setShowISIModal}) => {
  const user = useSelector((state) => state.auth.user);
  const [ISImodalContent, setISIModalContent] = useState("");
  useEffect(() => {
    if (!user) return;
    const fetchISIModal = async () => {
      const { success, content } = await fetchContent("ISIModal", user?.brand);
      if (success) {
        setISIModalContent(content?.content);
      }
    };
    fetchISIModal();
  }, [user]);

  return (
    <Modal
      centered
      open={true}
      width={1000}
      closable={true}
      onCancel={() => {
        setShowISIModal(false);
        clearCookie("firstTime");
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="p-6"
      footer={null}
    >
      <div className="p-6 flex flex-col items-center space-y-4">
        <div className="w-14 h-14 bg-primary/20 rounded-full flex items-center justify-center">
          <MdHealthAndSafety className="w-8 h-8 text-primary" />
        </div>
        <h2 className="text-xl font-semibold">IMPORTANT SAFETY INFORMATION</h2>
        <p className="text-sm text-gray-500 text-center text-[#337ab7]">
          Please read the following important safety information for{" "}
          {user?.brand}.
        </p>
      </div>
      <div className="max-h-[30rem] overflow-y-auto text-[#337ab7]">
        <div
          className="flex flex-col items-left"
          dangerouslySetInnerHTML={{ __html: ISImodalContent }}
        />
      </div>
    </Modal>
  );
};

export default ISIModal;
