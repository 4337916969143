import React, { useState, useEffect } from "react";
import { Modal, Checkbox } from "antd";
import { TfiPencilAlt } from "react-icons/tfi";
import { submitFeedback } from "../../../utils/handleRequest";
import { toast } from "sonner";

const feedbackTexts = {
  checkboxLabel: "Please select the things you like about Jawaab:",
  checkboxOptions: [
    { value: "quality", label: "Quality of content" },
    { value: "navigation", label: "Navigation" },
    { value: "avatar", label: "Avatar" },
    { value: "followup", label: "Ease of follow-up" },
  ],
  additionalCommentsLabel: "Additional comments:",
  additionalCommentsPlaceholder: "Enter additional comments",
  otherQuestionsLabel: "Are there any other questions you want to ask AZ?",
  otherQuestionsPlaceholder: "Enter your questions",
};

export default function FeedbackForm({ open, setOpen, formId }) {
  const [feedback, setFeedback] = useState({
    likes: [],
    additionalComments: "",
    otherQuestions: "",
  });
  const handleSubmit = async () => {
    const resp = await submitFeedback(JSON.stringify(feedback), formId);
    if (resp.success) {
      toast.success(resp.message);
      setOpen(false);
    } else {
      toast.error(resp.message);
    }
  };

  const handleLikesChange = (checkedValues) => {
    setFeedback((prev) => ({ ...prev, likes: checkedValues }));
  };
  const CHARACTER_LIMIT = 300;

  return (
    <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
      <div className="w-full max-w-md mx-auto p-8 rounded-lg">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-14 h-14 bg-yellow-100 rounded-full flex items-center justify-center">
            <TfiPencilAlt className="w-8 h-8 text-yellow-500" />
          </div>
          <h2 className="text-2xl font-semibold">Help us improve</h2>
          <p className="text-center text-gray-600">
            Fill in the feedback form to help us improve.
          </p>
        </div>
        <div className="space-y-4 mt-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {feedbackTexts.checkboxLabel}
            </label>
            <Checkbox.Group onChange={handleLikesChange}>
              <div className="space-y-2">
                {feedbackTexts.checkboxOptions.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </div>
            </Checkbox.Group>
          </div>
          <div className="space-y-2">
            <label
              htmlFor="additionalComments"
              className="block text-sm font-medium text-gray-700"
            >
              {feedbackTexts.additionalCommentsLabel}
            </label>
            <textarea
              id="additionalComments"
              maxLength={CHARACTER_LIMIT}
              placeholder={feedbackTexts.additionalCommentsPlaceholder}
              className="p-2 border rounded-md w-full border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
              value={feedback.additionalComments}
              onChange={(e) =>
                setFeedback((prev) => ({
                  ...prev,
                  additionalComments: e.target.value,
                }))
              }
            />
            <div className="text-right text-xs text-gray-500">
              {feedback.additionalComments.length}/{CHARACTER_LIMIT} characters
            </div>
          </div>
          <div className="space-y-2">
            <label
              htmlFor="otherQuestions"
              className="block text-sm font-medium text-gray-700"
            >
              {feedbackTexts.otherQuestionsLabel}
            </label>
            <textarea
              id="otherQuestions"
              placeholder={feedbackTexts.otherQuestionsPlaceholder}
              className="p-2 border rounded-md w-full border-gray-300 focus:outline-none focus:ring-1 focus:ring-primary"
              style={{ height: "100px", overflowY: "auto" }}
              maxLength={CHARACTER_LIMIT}
              value={feedback.otherQuestions}
              onChange={(e) =>
                setFeedback((prev) => ({
                  ...prev,
                  otherQuestions: e.target.value,
                }))
              }
            />
            <div className="text-right text-xs text-gray-500">
              {feedback.otherQuestions.length}/{CHARACTER_LIMIT} characters
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <button
            className="px-4 py-2 font-semibold text-white bg-primary rounded-md hover:bg-primary/70"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}
