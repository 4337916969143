import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import instance from "../../../redux/actions/AxiosInstance/unAuthenticated";
import { toast } from "sonner";
import ValidatorUtils from "utils/validatorUtils";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import LoadingButton from "components/LoadingButton";

const validatorUtils = new ValidatorUtils();

function CreatePassword() {
  const [token, setToken] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    let params = new URLSearchParams(url.split("?")[1]);
    let token = params.get("token");
    setToken(token);
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();

    const password = e.target.elements.password.value;
    const confirmPassword = e.target.elements.confirmPassword.value;

    // Validate password
    if (!validatorUtils.isPasswordValid(password)) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    // Validate password confirmation
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      setSpinner(true);
      const { data, status } = await instance.post("/user/reset-password", {
        password: password,
        token: token,
      });
      if (status === 200) {
        toast.success(data.message);
        navigate("/signin");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <div className="grid place-content-center items-center h-screen w-full">
      <div className="px-3 w-full">
        <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] mt-4">
          Create new password
        </p>
        <p className="font-Inter text-center text-sm text-[#475467] mt-2">
          Enter your new password and confirm it
        </p>
        <form onSubmit={resetPassword}>
          <div className="mt-4">
            <label
              htmlFor="password"
              className="text-[#4E5674] text-sm font-[500] font-Inter"
            >
              New Password *
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 ">
              <Input.Password
                className="border-none outline-none w-full font-Inter"
                name="password"
                id="password"
                placeholder="New Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                required
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              htmlFor="confirmPassword"
              className="text-[#4E5674] text-sm font-[500] font-Inter"
            >
              Confirm Password *
            </label>
            <div className="mt-1 flex items-center rounded-lg border-2 ">
              <Input.Password
                className="border-none outline-none w-full font-Inter"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                required
              />
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-4 mt-5">
            {spinner ? (
              <LoadingButton />
            ) : (
              <button
                type="submit"
                className="text-[12px] text-sm w-full cursor-pointer font-semibold font-Inter px-5 rounded-lg py-2 text-white bg-primary shadow-navButton hover:shadow-navButton border-[1px] border-primary "
              >
                Create password
              </button>
            )}
          </div>
        </form>
        <p
          onClick={() => {
            navigate("/signin");
          }}
          className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-5 flex items-center justify-center gap-x-3"
        >
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
              stroke="#475467"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to log in
        </p>
      </div>
    </div>
  );
}

export default CreatePassword;
