import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { fetchContent } from "../../../content/FetchContent";
import CenterVideoLayout from "../components/CenterVideoLayout";

export const Idle = () => {
  const user = useSelector((state) => state.auth.user);

  const [content, setContent] = useState({
    default: [],
    filterQuestion: {},
    filters: [],
  });

  useEffect(() => {
    if (user != null) {
      const fetchScript = async () => {
        const { success, content } = await fetchContent(
          "homeScreen",
          user?.brand
        );

        if (success) setContent(content);
      };
      fetchScript();
    }
  }, [user]);

  return (
    <div>
      {content && content.layout === "CenterVideoLayout" && (
        <CenterVideoLayout content={content} />
      )}
    </div>
  );
};

export default Idle;
