import React from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { Navbar } from "./Navbar";
import { getCookie, clearHCPCookies, setCookie } from "../utils/cookie";
import TermsOfUseModal from "./TermsOfUseModal";
import ISIModal from "./ISIModal";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const [showModal, setShowModal] = React.useState(true);
  const [showISIModal, setShowISIModal] = React.useState(false);

  const handleAccept = async () => {
    try {
      const decodedToken = decodeToken(inviteId);
      if (decodedToken && decodedToken.exp) {
        const expirationTimestamp = decodedToken.exp;
        const date = new Date(expirationTimestamp * 1000);
        const expires = date.toUTCString();
        setCookie("accessToken", inviteId, expires);
      }
      setShowModal(false);
    } catch (error) {
      navigate("/signin");
    }
  };

  const ISIBanner = (
    <div className="fixed bottom-0 w-full bg-white py-1 text-center z-50">
      <p
        className="text-sm cursor-pointer text-blue-800 font-semibold"
        onClick={() => setShowISIModal(true)}
      >
        IMPORTANT SAFETY INFORMATION (Click Here to Read More)
      </p>
      <p className="text-xs italic mt-1 text-blue-800 max-w-xl lg:max-w-2xl mx-auto">
        See important safety information including warnings and precautions,
        adverse reactions, drug interactions, and use in specific populations...
      </p>
    </div>
  );

  const poweredByJawaab = (
    <div className="self-center text-sm text-gray-600">
      <p>Powered by JAWAAB™</p>
      <span className="text-xs">a ValueDo Life Sciences Advisory Product</span>
    </div>
  );

  const healthcareProfessionalOnlyBanner = (
    <div className="fixed top-0 w-full bg-yellow-100 py-1.5 text-center flex items-center justify-center z-50">
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 ml-5 md:ml-0 text-gray-700 flex-shrink-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="text-sm cursor-pointer md:ml-0">
          This information is meant for US healthcare professionals only
        </p>
      </div>
    </div>
  );

  // Handle inviteId case
  if (inviteId) {
    return (
      <>
        {showModal ? (
          <TermsOfUseModal onAccept={handleAccept} />
        ) : (
          <>
            {showISIModal && <ISIModal setShowISIModal={setShowISIModal} />}
            {healthcareProfessionalOnlyBanner}
            <div className="md:pt-0 pt-8 md:pb-0 pb-16">
              <div className="pt-8">
                <Navbar />
              </div>

              <Outlet />
            </div>
            {ISIBanner}
          </>
        )}
      </>
    );
  }

  // Handle cases where inviteId is not present
  if (!inviteId) {
    if (window.location.pathname.includes("/pharma")) {
      return <Outlet />;
    }

    const auth = getCookie("accessToken");

    if (!auth) {
      return <Navigate to="/signin" />;
    }

    const isMyTokenExpired = isExpired(auth);
    if (isMyTokenExpired) {
      clearHCPCookies();
      return <Navigate to="/signin" />;
    }

    return (
      <>
        {showISIModal && <ISIModal setShowISIModal={setShowISIModal} />}
        {healthcareProfessionalOnlyBanner}
        <div className="md:pt-0 pt-8 md:pb-0 pb-16">
          <div className="pt-8">
            <Navbar />
          </div>

          <Outlet />
        </div>
        {ISIBanner}

        <div className="fixed bottom-0 right-0 p-2 px-3 z-[60] text-center hidden md:block">
          {poweredByJawaab}
        </div>
      </>
    );
  }

  return <Navigate to="/signin" />;
};

export default PrivateRoutes;
