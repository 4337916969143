import { AreaChart, LineChart } from "@tremor/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space, Tooltip } from "antd";

function MetricLineChart(props) {
  const {
    title,
    color,
    category,
    showButtons,
    chartdata,
    metricFilter,
    setMetricFilter,
    selectedFilter,
    setSelectedFilter,
  } = props;
  const [value, setValue] = useState(null);
  const [activeButton, setActiveButton] = useState(0);
  const { textColor } = useSelector((state) => state.colors);

  const items = [
    {
      label: "Last Week",
      key: "1",
    },
    {
      label: "Last Month",
      key: "2",
    },
    {
      label: "3 Months",
      key: "3",
    },
    {
      label: "1 Year",
      key: "4",
    },
  ];
  const handleMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setMetricFilter({ ...metricFilter, [title]: selectedItem.label });
      setSelectedFilter({ metric: title, period: selectedItem.label });
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-300 p-2 rounded shadow">
          <p className="text-sm">{`Date: ${label}`}</p>
          <hr className="my-1 border-gray-300" />
          <p className="text-sm font-semibold">{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-y-5 border border-gray-300 rounded-lg p-5">
      <div className="flex justify-between items-center p-3">
        <h1 className="text-xl font-semibold">{title}</h1>
        <div className="flex gap-x-1 items-center">
          {showButtons && (
            <Dropdown menu={menuProps}>
              <Button>
                <Space>
                  {selectedFilter.period ? selectedFilter.period : "Last Week"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      <AreaChart
        className="mt-4 h-72"
        data={chartdata}
        index="date"
        showLegend={false}
        curveType="monotone"
        categories={category}
        showAnimation={true}
        colors={[color]}
        yAxisWidth={30}
        onValueChange={(v) => setValue(v)}
        connectNulls={true}
        customTooltip={CustomTooltip}
      />
    </div>
  );
}

export default MetricLineChart;
