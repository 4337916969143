/**
 * Log class file
 **/

// This list includes data to be obfuscated, pii and other sensitive data
const privacy = [
  "password",
  "email",
  "firstName",
  "lastName",
  "loginPassword",
  "contactPhone",
  "phoneNumber",
  "passwordHistory",
  "passwordResetValue",
  "passwordResetCheck",
  "passwordResetIdentifier",
  "address",
  "gender",
  "fullName",
  "token",
  "facilityPatientId",
  "normalizePhoneNumber",
];

const ObjType = {
  OBJ: "[object Object]",
  ARR: "[object Array]",
};

const obfuscate = function () {
  return "****";
};

/**
 * Obfuscates all attribute values recursively
 * @param {*} item
 */
function obfuscateObject(item) {
  Object.keys(item).forEach((key) => {
    if (Object.prototype.toString.call(item[key]) === ObjType.OBJ) {
      obfuscateObject(item[key]);
    } else {
      item[key] = obfuscate();
    }
  });
}

const filter = function (item) {
  if (typeof item === "object") {
    Object.keys(item).forEach((key) => {
      if (
        Object.prototype.toString.call(item[key]) === ObjType.OBJ ||
        Object.prototype.toString.call(item[key]) === ObjType.ARR
      ) {
        if (privacy.includes(key)) {
          obfuscateObject(item[key]);
        } else {
          filter(item[key]);
        }
      } else if (privacy.includes(key)) {
        item[key] = obfuscate();
      }
    });
  }
  return item;
};

const clone = function (item) {
  if (typeof item === "string") {
    return item.slice();
  }
  if (typeof item !== "undefined") {
    return JSON.parse(JSON.stringify(item));
  }
  return { ...item };
};

/**
 *  Writes logs
 *  @param {string} logLevel
 *  @param {string} message
 *  @param {mixed} item
 */
const log = (logLevel, message, item = undefined, test = false) => {
  // Let's make a copy to avoid obfuscating the original item
  let itemClone = clone(item);
  // Case of logs that just throw an object but no message
  if (typeof message !== "string" && !itemClone) {
    itemClone = message;
    message = "";
  }

  itemClone = filter(itemClone);

  if (test) {
    return { logLevel, message, item: itemClone };
  }
  console[logLevel](message, itemClone);
  return true;
};

export default log;
