import { combineReducers } from "redux";

import product from "./product";
import list from "./list";
import auth from "./auth";
import colors from "./colors";
import conversation from "./conversation";

export default combineReducers({
  auth: auth,
  list: list,
  product: product,
  colors: colors,
  conversation: conversation,
});
