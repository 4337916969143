import { useRef, useState } from "react";

const useVideoControls = () => {
  const videoRef = useRef(null);
  const [muteButton, setMuteButton] = useState(true);
  const [showPauseButton, setShowPauseButton] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setMuteButton(true);
    }
  };

  const togglePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setMuteButton(false);
    }
  };

  return {
    videoRef,
    muteButton,
    showPauseButton,
    setShowPauseButton,
    togglePlay,
    togglePause,
  };
};

export default useVideoControls;
