import React from "react";

const BrandNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "1rem",
        border: "1.5px solid #e2e8f0",
        borderRadius: ".8rem",
        backgroundColor: "#F9FAFB",
        height: "100vh", // take up full height of viewport
        gap: "1rem",
      }}
      className="flex-wrap items-center md:mt-[10%] mt-5"
    >
      <h1>404 - Brand Not Found!</h1>
      <p style={{ fontSize: "1.2rem" }}>
        Please specify the correct brand you want to log in to in the URL.
      </p>
    </div>
  );
};

export default BrandNotFound;
