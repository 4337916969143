import React, { useContext, useEffect } from "react";
import AvatarContext from "../../../components/AvatarContext";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const LeftQuestionBox = ({ selectCategoryType, questions }) => {
  const [defaultQuestions, setDefaultQuestions] = React.useState([]);
  const { setQuestion, setRequestLoader, requestLoader } =
    useContext(AvatarContext);


  useEffect(() => {
    const selectedCategory = questions?.questions?.find(
      (item) => item.filterName === selectCategoryType
    );

    if (selectedCategory) {
      setDefaultQuestions(selectedCategory?.questions);
    } else {
      setDefaultQuestions(
        questions?.questions?.find((item) => item?.filterName === "default")
          ?.questions || []
      );
    }
  }, [selectCategoryType, questions]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center p-4 gap-4">
      {!requestLoader && (
        <>
          <div
            className="bg-gray-100 p-4 w-[95%]"
            data-intro="Click any question below to start a conversation!"
            data-position="top"
            data-step="1"
            style={{ border: "1.5px solid #e2e8f0", borderRadius: ".8rem" }}
          >
            {defaultQuestions?.map((item, index) => (
              <div
                key={index}
                className="flex gap-2 bg-white rounded-lg p-4 my-5 cursor-pointer"
                style={{ border: "1.5px solid #e2e8f0", borderRadius: ".8rem" }}
                onClick={(e) => {
                  setQuestion(item);
                  setRequestLoader(true);
                }}
              >
                <AiOutlineQuestionCircle className="h-5 w-5 text-gray-600" />
                <div className="text-sm flex-1">{item}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LeftQuestionBox;
