import React from "react";
import Loader from "../../components/Loader";
import useUserVerification from "./modules/useUserVerification";
import useVideoControls from "./modules/useVideoControls";
import WelcomeContent from "./features/WelcomeContent";

const Welcome = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const { loader, content } = useUserVerification(inviteId);
  const videoControls = useVideoControls();

  if (loader) {
    return <Loader />;
  }

  if (!content) {
    return null;
  }

  return <WelcomeContent content={content} videoControls={videoControls} />;
};

export default Welcome;
