import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Checkbox, Radio } from "antd";
import { getScript } from "../utils/handleRequest";
import Loader from "../components/Loader";
import log from "../utils/log";

const ChooseAvatar = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [settings, setSettings] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchData = async () => {
      try {
        const resp = await getScript("settings", user?.brand);
        if (resp.success) {
          setSettings(resp?.data);
        } else {
          log("error", "Error fetching script", resp?.data);
        }
      } catch (error) {
        log("error", "Error fetching script", error);
      }
    };

    fetchData();
  }, [user]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    setIsSelected(!isSelected);
  };

  const imageClick = (image) => {
    setSelectedImage(image);
  };

  if (!settings) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div
        className="p-6 bg-white  rounded-lg"
        style={{
          width: "69%",
          height: "61rem",
          marginLeft: "13%",
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            margin: "1rem",
            alignSelf: "flex-end",
            position: "absolute",
          }}
        >
          <Link to={settings.button.value}>{settings.button.text}</Link>
        </Button>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {settings.title}
        </h5>
        <div
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
        >
          <div
            style={{
              marginTop: "2rem",
              textAlign: "left",
            }}
          >
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              {settings?.options.avatars.title}
            </p>
            <div
              className="grid grid-cols-2 md:grid-cols-3 gap-5"
              style={{ width: "51rem", textAlign: "center" }}
            >
              {settings?.options.avatars.options.map((avatar) => (
                <div
                  key={avatar.name}
                  onClick={() => imageClick(avatar.url)}
                  style={{
                    border:
                      selectedImage === avatar.url ? "2px solid blue" : "none",
                    borderRadius: "10px",
                    background: "#80808014",
                  }}
                >
                  <img
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                    }}
                    className="h-auto max-w-full rounded-lg"
                    src={avatar.url}
                    alt={avatar.name}
                  />
                  <p>{avatar.name}</p>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "3rem" }}>
              <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
                {settings?.options.accents.title}
              </p>
              <div style={{ marginTop: "1rem" }}>
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {settings?.options.accents.options.map((accent) => (
                    <Radio
                      key={accent.value}
                      style={{ padding: "1rem", background: "#80808014" }}
                      value={accent.value}
                    >
                      {accent.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            </div>
            <div style={{ marginTop: "3rem" }}>
              <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
                {settings?.options.modes.title}
              </p>
              {settings?.options.modes.options.map((mode) => (
                <Checkbox
                  key={mode.value}
                  style={{ marginRight: "2rem" }}
                  defaultChecked={mode.defaultChecked}
                >
                  {mode.label}
                </Checkbox>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAvatar;
