import React, { useContext } from "react";
import { FaHistory } from "react-icons/fa";
import AvatarContext from "../../../components/AvatarContext";

const PersonalizationBox = ({
  personalizedQuest,
  user,
  personalizationOption,
}) => {
  const { setQuestion, setRequestLoader } = useContext(AvatarContext);

  const getMessage = (template) => {
    return template
      .replace("{prefix}", user?.prefix || "")
      .replace("{lastName}", user?.lastName || "")
      .replace("{category}", personalizedQuest?.category || "");
  };

  return (
    personalizedQuest &&
    personalizationOption && (
      <div
        className="flex flex-col items-center justify-center bg-indigo-100 animate-fade-in w-[90%] md:w-full max-w-4xl mx-auto z-10 px-6 md:mx-3 py-4 md:p-6"
        style={{
          border: "1.5px solid rgb(165 180 252)",
          borderRadius: ".8rem",
        }}
      >
        <p className="font-semibold text-center text-sm md:text-base mb-4">
          {getMessage(personalizationOption.message)}
        </p>
        <div className="flex flex-col md:flex-row md:flex-wrap w-full gap-3 justify-center">
          {personalizedQuest?.questions
            .slice(0, personalizationOption.noOfQuestions)
            .map((question, index) => (
              <div
                key={index}
                className="flex gap-2 bg-white rounded-lg p-3 md:p-4 items-center cursor-pointer w-full md:w-[calc(50%-0.75rem)]"
                style={{
                  border: "1.5px solid #e2e8f0",
                }}
                onClick={() => {
                  setQuestion(question);
                  setRequestLoader(true);
                }}
              >
                <FaHistory className="h-4 w-4 md:h-5 md:w-5 text-gray-600 flex-shrink-0" />
                <div className="text-xs md:text-sm">{question}</div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default PersonalizationBox;