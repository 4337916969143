import React, { useState, useEffect } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DoctorCategoryChart from "../components/DoctorCategoryChart";
// import AnswerModal from "../components/AnswerModal";
import { useSelector } from "react-redux";
import DoctorBarChart from "../components/BarChart";
import UnAnsweredQuestions from "../components/unAnsweredQuestions";
import AnsweredQuestions from "../components/AnsweredQuestions";
import { getDashboardConversationMetrics } from "../redux/actions/auth";

const DoctorProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { admin } = useSelector((state) => state.auth);
  const {
    id = "",
    name = "",
    email = "",
    questions = [],
    unanswered = [],
  } = location?.state || {};

 const [showQuestionModal, setShowQuestionModal] = useState(false);
 const [question, setQuestion] = useState({});

 useEffect(() => {
   if (!admin?.brand?.id) return;
   if (admin?.brand?.brandName !== params?.brand) {
     navigate(`/pharma/unauthorized`, {
       state: { brandName: params?.brand },
     });
     return;
   }
   getDashboardConversationMetrics(
     "",
     admin?.brand?.id,
     "User Questions",
     id
   ).then(({ success, data }) => {
     if (success) {
       setQuestion(data.data);
     }
   });
 }, [admin]);

  return (
    <div>
      {/* {showQuestionModal && (
        <AnswerModal
          handleClose={() => setShowQuestionModal(false)}
          question={question}
        />
      )} */}
      <div className="p-5 border-b border-b-gray-300 flex items-center gap-x-4 title">
        <IoChevronBackOutline
          className="text-gray-700 cursor-pointer"
          onClick={() =>
            navigate(`/pharma/${admin?.brand?.brandName}/dashboard`)
          }
        />
        <p>{name}</p>
      </div>
      <div className="flex items-center gap-x-3 text-gray-700 p-5 ">
        <div className="w-[50%] h-[16rem] rounded-lg border border-gray-200 flex flex-col gap-y-3 p-4">
          <h1 className="text-2xl font-medium">{name}</h1>
          <p className="text-gray-600">{email}</p>
          <p>
            Questions asked: <span className="font-semibold">{questions}</span>
          </p>
        </div>
        <div className="w-full rounded-lg border border-gray-200 p-4 flex items-center justify-between">
          <DoctorCategoryChart questions={questions} unanswered={unanswered} />
          <div className="w-1/2 flex flex-col gap-y-2">
            <h1 className="text-xl font-semibold">
              Questions asked by category
            </h1>
            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <p className="p-1 rounded-full bg-[#3B82F6] w-fit"></p>
                <p>Answered</p>
              </div>
              <p className="ml-5">{questions - unanswered}</p>
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <p className="p-1 rounded-full bg-[#0EA5E9] w-fit"></p>
                <p>Un Answered</p>
              </div>
              <p className="ml-5">{unanswered}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-5">
        <DoctorBarChart userId={id} />
        <UnAnsweredQuestions question={question?.unansweredQuestions} />
        <AnsweredQuestions question={question?.answeredQuestions} />
      </div>
    </div>
  );
};

export default DoctorProfile;
