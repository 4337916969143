import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MetricLineChart from "../components/MetricLineChart";
import { getDashboardUserMetrics } from "../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const UserMetric = () => {
  const { admin } = useSelector((state) => state.auth);
  const [newUsers, setNewUsers] = useState([]);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [userInteractions, setUserInteractions] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [metricFilter, setMetricFilter] = useState({
    "New Users": "Last Week",
    "New Invites": "Last Week",
    "User Interactions": "Last Week",
  });
  const [selectedFilter, setSelectedFilter] = useState({
    metric: "",
    period: "",
  });
  const [currentFilter, setCurrentFilter] = useState({
    "New Users": {
      period: "Last Week",
    },
    "New Invites": {
      period: "Last Week",
    },

    "User Interactions": {
      period: "Last Week",
    },
  });
  useEffect(() => {
    if (!admin?.brand?.id) return;
    getDashboardUserMetrics("Last Week", admin?.brand?.id).then(
      ({ success, data }) => {
        if (success) {
          data.data.forEach(({ metric, data }) => {
            let modifiedData = transformData(data, metric);
            if (metric === "New Users") {
              setNewUsers(modifiedData);
            } else if (metric === "New Invites") {
              setInviteUsers(modifiedData);
            } else if (metric === "User Interactions") {
              setUserInteractions(modifiedData);
            }
          });
        }
        setSpinner(false);
      }
    );
  }, [admin]);

  useEffect(() => {
    if (selectedFilter.metric === "" || selectedFilter.period === "") return;
    getDashboardUserMetrics(
      selectedFilter.period,
      admin?.brand?.id,
      selectedFilter.metric
    ).then(({ success, data }) => {
      if (success) {
        data.data.forEach(({ metric, data }) => {
          let modifiedData = transformData(data, metric);
          if (selectedFilter.metric === "New Users") {
            setNewUsers(modifiedData);
            setCurrentFilter({ ...currentFilter, "New Users": selectedFilter });
          }
          if (selectedFilter.metric === "New Invites") {
            setInviteUsers(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "New Invites": selectedFilter,
            });
          }
          if (selectedFilter.metric === "User Interactions") {
            setUserInteractions(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "User Interactions": selectedFilter,
            });
          }
        });
      }
    });
  }, [selectedFilter]);

  function transformData(input, metric) {
    return input.map((item) => ({
      date: item.date,
      [metric]: item.value,
    }));
  }
  return (
    <Spin
      tip="Loading User Metrics..."
      size="large"
      fullscreen={false}
      spinning={spinner}
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
        />
      }
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-[33%]">
          <MetricLineChart
            title={"New Users"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={newUsers}
            category={["New Users"]}
            color={"blue"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["New Users"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"New Invites"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={inviteUsers}
            category={["New Invites"]}
            color={"red"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["New Invites"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"User Interactions"}
            chartdata={userInteractions}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            category={["User Interactions"]}
            color={"indigo"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["User Interactions"]}
          />
        </div>
      </div>
    </Spin>
  );
};

export default UserMetric;
