// Navbar.js
import React, { useState, useEffect } from "react";
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Loader from "../Loader";
import instance from "../../redux/actions/AxiosInstance/authenticated";
import { clearHCPCookies } from "../../utils/cookie";
import {
  getScript,
  getUserInfo as fetchUserInfo,
  updateUser,
  shareToHCPs,
} from "../../utils/handleRequest";

import introJs from "intro.js";
import { useDispatch, useSelector } from "react-redux";
import {
  OptOutModal,
  PrivacyModal,
  EditProfileModal,
  ShareModal,
} from "./components";
import { getIcon } from "./modules";
import ValidatorUtils from "../../utils/validatorUtils";
import lynparzaLogo from "../../assets/images/lynparza-logo-s.svg";
import tenyearpng2 from "../../assets/images/10year2.png";
const validatorUtils = new ValidatorUtils();
const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedinUser = useSelector((state) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [optOUTmodal, setOptOUTmodal] = useState(false);
  const [optOutLoader, setOptOutLoader] = useState(false);
  const [user, setUser] = useState({
    name: "",
    avatar: "/images/Avatar.png",
    companyLogo: "/images/javaablogocopy.png",
    id: "",
    brand: "",
  });
  const [dropdownItems, setDropdownItems] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const currentPage = window.location.pathname;
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [shareJawaabModal, setShareJawaabModal] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const [hcp, setHcp] = useState({
    prefix: "",
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    if (loggedinUser !== null) {
      setUser({
        name: loggedinUser.name,
        prefix: loggedinUser.prefix,
        firstName: loggedinUser.firstName,
        lastName: loggedinUser.lastName,
        avatar: loggedinUser.avatar ? loggedinUser.avatar : user.avatar,
        companyLogo: loggedinUser.companyLogo
          ? loggedinUser.companyLogo
          : user.companyLogo,
        brand: loggedinUser.brand,
        id: loggedinUser.id,
        email: loggedinUser.email,
      });
      setHcp({
        prefix: loggedinUser.prefix,
        firstName: loggedinUser.firstName,
        lastName: loggedinUser.lastName,
      });

      fetchDropdownItems(loggedinUser.brand);
      return;
    }

    if (inviteId === null) {
      getUserInfo();
      return;
    }

    if (inviteId == null && loggedinUser === null) return getUserInfo();
  }, [loggedinUser]);

  async function getUserInfo() {
    try {
      setLoader(true);
      const resp = await fetchUserInfo();
      if (resp.success) {
        dispatch({
          type: "SET_USER",
          payload: resp.data,
        });

        setUser({
          name: resp.data.name,
          prefix: resp.data.prefix,
          firstName: resp.data.firstName,
          lastName: resp.data.lastName,
          avatar: resp.data.avatar ? resp.data.avatar : user.avatar,
          companyLogo: resp.data.companyLogo
            ? resp.data.companyLogo
            : user.companyLogo,
          brand: resp.data.brand,
          id: resp.data.id,
          email: resp.data.email,
        });
        setHcp({
          prefix: resp.data.prefix,
          firstName: resp.data.firstName,
          lastName: resp.data.lastName,
        });
        fetchDropdownItems(resp.data.brand);
      }
    } catch (error) {
      if (currentPage !== "/signin") {
        toast.error("Something went wrong!");
        navigate("/signin");
      }
    } finally {
      setLoader(false);
    }
  }

  async function fetchDropdownItems(brand) {
    try {
      const resp = await getScript("dropdownMenu", brand);
      if (resp.success) {
        const items = resp.data?.options?.buttons.map((item, index) => ({
          key: index.toString(),
          label: (
            <a
              className="text-gray-700"
              onClick={(e) =>
                handleMenuItemClick(e, item.functionName, item.value)
              }
              style={{ cursor: "pointer" }}
            >
              <div className="flex items-center gap-x-2">
                {getIcon(item.icon)}
                {item.name}
              </div>
            </a>
          ),
        }));
        setDropdownItems(items);
      } else {
        toast.error("Failed to fetch dropdown items.");
      }
    } catch (error) {
      toast.error("Something went wrong while fetching dropdown items.");
    }
  }

  async function handleMenuItemClick(e, functionName, value) {
    e.preventDefault();
    switch (functionName) {
      case "productTour":
        productTour();
        break;
      case "privacyPolicy":
        privacyPolicy();
        break;
      case "editProfile":
        setEditProfileModal(true);
        break;
      case "signOut":
        signOut();
        break;
      case "optOut":
        setOptOUTmodal(true);
        break;
      case "shareJawaab":
        shareJawaabToHCP();
        break;
      default:
        if (value) {
          window.open(value);
        }
        break;
    }
  }

  const privacyPolicy = () => {
    setPrivacyPolicyModal(true);
  };

  const productTour = () => {
    introJs().start();
  };

  const signOut = async () => {
    try {
      const { data } = await instance.get("/user/logout");
      if (data.success) {
        clearHCPCookies();
        navigate("/signin");
        window.location.reload(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const optOut = async () => {
    try {
      setOptOutLoader(true);
      const { data } = await instance.get("/user/opt-out");
      if (data.success) {
        toast.success(data.message);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        clearHCPCookies();
        navigate("/signin");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setOptOutLoader(false);
      setOptOUTmodal(false);
    }
  };

  const shareJawaabToHCP = async () => {
    try {
      let userId = loggedinUser ? loggedinUser.id : user.id;
      let brand = loggedinUser ? loggedinUser.brand : user.brand;
      const { success, data } = await shareToHCPs(brand, userId);
      if (success) {
        setShareLink(data.shareLink);
        setShareJawaabModal(true);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const updateProfile = async () => {
    try {
      const firstNameValidation = validatorUtils.isFirstNameValid(
        hcp.firstName
      );
      if (!firstNameValidation.isValid) {
        toast.error(firstNameValidation.message);
        return;
      }

      const lastNameValidation = validatorUtils.isLastNameValid(hcp.lastName);
      if (!lastNameValidation.isValid) {
        toast.error(lastNameValidation.message);
        return;
      }
      setLoader(true);
      const { success, message } = await updateUser({
        prefix: hcp.prefix,
        firstName: hcp.firstName,
        lastName: hcp.lastName,
      });

      if (success) {
        toast.success(message);
        setEditProfileModal(false);
        await getUserInfo();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error(error);
        toast.error("Something went wrong!");
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      {loader && (
        <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
          <Loader />
        </div>
      )}
      <OptOutModal
        open={optOUTmodal}
        onOk={optOut}
        loading={optOutLoader}
        onCancel={() => setOptOUTmodal(false)}
      />
      <PrivacyModal
        open={privacyPolicyModal}
        onCancel={() => setPrivacyPolicyModal(false)}
      />
      <EditProfileModal
        open={editProfileModal}
        onCancel={() => setEditProfileModal(false)}
        hcp={hcp}
        setHcp={setHcp}
        onOk={updateProfile}
      />
      <ShareModal
        open={shareJawaabModal}
        onCancel={() => setShareJawaabModal(false)}
        shareLink={shareLink}
      />
      <div className="flex justify-between items-center px-4">
        {user?.brand === "lynparza" ? (
          <div className="h-12 w-26 rounded-md pt-1 mt-1">
            <img
              className="h-full w-full"
              src={lynparzaLogo}
              alt="lynparza-logo"
            />
          </div>
        ) : (
          <img
            src="https://res.cloudinary.com/ds80j9gac/image/upload/v1709885447/jawaab_latest_ab35eb.png"
            alt="jawaab"
            className="w-auto"
            style={{
              height: "4rem",
              background: "white",
              width: "4rem",
              zIndex: 9,
            }}
            onClick={() => navigate("/")}
          />
        )}

        <div className="flex items-center gap-5">
          {user?.brand === "lynparza" && (
            <>
              <div className="h-12 w-26 rounded-md">
                <img
                  className="h-full w-full"
                  src={tenyearpng2}
                  alt="tenyear-logo"
                />
              </div>
            </>
          )}

          <div className="flex items-center space-x-2 ml-auto">
            <img className="h-6 w-6 rounded-full" src={user.avatar} alt="" />
            <Dropdown menu={{ items: dropdownItems }}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ cursor: "pointer" }}
              >
                {user.prefix} {user.lastName}, <br className="md:hidden" />
                {user.firstName}
                <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      <hr className="border-gray-300" />
    </div>
  );
};

export default Navbar;
