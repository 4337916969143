import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "1rem",
        border: "1.5px solid #e2e8f0",
        borderRadius: ".8rem",
        backgroundColor: "#F9FAFB",
        height: "100vh", // take up full height of viewport
      }}
      className="flex-wrap items-center md:mt-[10%] mt-5"
    >
      <h1>404 - Not Found!</h1>
      <p>The page you are looking for doesn't exist.</p>
    </div>
  );
};

export default NotFound;
