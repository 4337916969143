import React from "react";
import { IoFunnel } from "react-icons/io5";
import { Dropdown, Button } from "antd";
import { findByTestId } from "@testing-library/react";

const FilterQuestions = ({
  selectCategoryType,
  setSelectCategoryType,
  filterOptions,
}) => {
  const handleSelectCategory = (category) => {
    setSelectCategoryType(category);
  };

  const items = filterOptions?.map((each, index) => {
    return {
      key: index.toString(),
      label: (
        <Button
          onClick={() => handleSelectCategory(each)}
          className={`w-40 ${
            selectCategoryType === each
              ? "bg-primary hover:bg-primary hover:text-primary"
              : "bg-transparent"
          }`}
        >
          {each}
        </Button>
      ),
    };
  });

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="topLeft"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <IoFunnel
        id="filter-icon"
        size={60}
        className="border rounded-md p-4 m-1 cursor-pointer bg-white text-gray-400"
      />
    </Dropdown>
  );
};

export default FilterQuestions;
