import React, { useContext } from "react";
import { Button, Input } from "antd";
import AvatarContext from "./AvatarContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import annyang from "annyang";

const AnswerNotFound = ({options}) => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const {
    descriptionTitle,

    setConversation,
    setListening,
    playVideo,
  } = useContext(AvatarContext);

  return (
    <div
      style={{
        display: "flex",
        margin: "1rem",
        border: "1.5px solid #e2e8f0",
        borderRadius: ".8rem",
        backgroundColor: "#F9FAFB",
      }}
      className="flex-wrap items-center md:mt-[10%] mt-5"
    >
      <img
        alt="404"
        src="images/notFound.png"
        style={{
          alignSelf: "center",
        }}
        className="md:w-[45%] w-3/4 md:mx-0 mx-auto"
      />
      <div
        key={descriptionTitle}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
        }}
        className="flex-1 animate-fade-in-1s"
      >
        <p className="text-2xl font-semibold">{options?.title}</p>
        <p style={{ marginTop: "0.5rem" }} className="text-gray-500">
          {options?.description}
        </p>

        <TextArea
          placeholder="question"
          rows={4}
          style={{ marginTop: "1.5rem" }}
          className="outline-none p-2 text-lg text-gray-600 w-[90%]"
          value={descriptionTitle}
        />
        <div className="flex flex-wrap gap-x-2 gap-y-2.5 items-center mt-5">
          <Button
            onClick={() => {
              window.open(
                options?.buttons.find((b) => b.key === "sendToMedicalInfo")
                  .value,
                "_blank"
              );
            }}
            className="bg-primary text-white rounded-full px-4 font-medium w-fit py-2 flex items-center justify-center"
          >
            {options?.buttons.find((b) => b.key === "sendToMedicalInfo")?.text}
          </Button>
          <Button
            onClick={() => {
              setConversation(false);
              if (annyang) annyang.abort();
              setListening(false);
              playVideo(false);
            }}
            className="rounded-full"
          >
            Go Back
          </Button>
          <Button
            onClick={() => {
              window.open(
                "https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/us/en/amp-form.html?country=us&lang=en",
                "_blank"
              );
            }}
            className="bg-gray-500 text-white rounded-full px-4 font-medium w-fit py-2 flex items-center justify-center"
          >
            Please report adverse events here
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnswerNotFound;
