const setup = {
  welcome: {
    screen: "Welcome",
    title: "Welcome to Airsupra Jawaab",
    subtitle: "Navigating Health Solutions: Join the Conversation on Jawaab",
    description:
      "Unlock the potential of collaborative healthcare with Jawaab. This platform serves as a nexus for doctors and pharmacy companies to exchange insights, discuss medications, and streamline communication",
    video: "/videos/welcome.mp4",
    button: "Next",
  },
};

export default setup;
