import { Card, DonutChart } from "@tremor/react";

const valueFormatter = (number) =>
  `${new Intl.NumberFormat("us").format(number).toString()} Questions`;

const DoctorCategoryChart = ({ questions, unanswered }) => (
  <Card className="w-[40%]">
    <DonutChart
      className="w-full h-44"
      data={[
        { name: "Answered", questions: questions - unanswered },
        { name: "Un Answered", questions: unanswered },
      ]}
      category="questions"
      index="name"
      valueFormatter={valueFormatter}
      colors={["blue", "sky"]}
      showAnimation={true}
      showLabel={true}
    />
  </Card>
);
export default DoctorCategoryChart;
