// ValidatorUtils.js

class ValidatorUtils {
  // Constants for minimum and maximum values
  EMAIL_MAX_LENGTH = 60;
  EMAIL_MIN_LENGTH = 5;
  LAST_NAME_MIN_LENGTH = 1;
  LAST_NAME_MAX_LENGTH = 60;
  FIRST_NAME_MIN_LENGTH = 1;
  FIRST_NAME_MAX_LENGTH = 60;
  PHONE_NUMBER_MIN_LENGTH = 8;
  PHONE_NUMBER_MAX_LENGTH = 50;
  BIRTHDAY_MIN_YEAR = 1850;
  POSTAL_CODE_MIN_LENGTH = 2;
  POSTAL_CODE_MAX_LENGTH = 20;
  PASSWORD_MIN_LENGTH = 8;
  PASSWORD_MAX_LENGTH = 50;

  constructor() {
    this.isValid = {
      email: (x) => this.isEmailValid(x),
      firstName: (x) => this.isFirstNameValid(x),
      lastName: (x) => this.isLastNameValid(x),
      phoneNumber: (x) => this.isPhoneNumberValid(x),
      birthday: (x) => this.isBirthdayValid(x),
      password: (x) => this.isPasswordValid(x),
      passwordConfirmed: (x) => this.isPasswordConfirmedValid(x),
    };
  }

  // Numeric validation
  isNumerValid(number) {
    if (!number.match(/^[0-9.]+$/)) {
      return false;
    }
    if (this.repeatsChar(number, ".") > 1) {
      return false;
    }
    if (isNaN(number.charAt(0))) {
      return false;
    }
    return true;
  }

  // Helper method to count occurrences of a character in a string
  repeatsChar(string, char) {
    return string.split(char).length - 1;
  }

  // Validation for one decimal place
  hasOneDecimal(number) {
    return /^\d{2}(\.\d{1})$/.test(number);
  }

  // Email validation
  isEmailValid(email) {
    if (
      email.length < this.EMAIL_MIN_LENGTH ||
      email.length > this.EMAIL_MAX_LENGTH
    ) {
      return false;
    }
    const emailRegex = new RegExp(
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    );
    return emailRegex.test(email);
  }

  // First name validation
  isFirstNameValid(firstName) {
    if (firstName.length < this.FIRST_NAME_MIN_LENGTH) {
      return { isValid: false, message: "First name is too short" };
    }
    if (firstName.length > this.FIRST_NAME_MAX_LENGTH) {
      return { isValid: false, message: "First name is too long" };
    }
    return { isValid: true, message: "" };
  }

  // Last name validation
  isLastNameValid(lastName) {
    if (lastName.length < this.LAST_NAME_MIN_LENGTH) {
      return { isValid: false, message: "Last name is too short" };
    }
    if (lastName.length > this.LAST_NAME_MAX_LENGTH) {
      return { isValid: false, message: "Last name is too long" };
    }
    return { isValid: true, message: "" };
  }

  // Phone number validation
  isPhoneNumberValid(phoneNumber) {
    if (
      phoneNumber.length < this.PHONE_NUMBER_MIN_LENGTH ||
      phoneNumber.length > this.PHONE_NUMBER_MAX_LENGTH
    ) {
      return false;
    }
    return true;
  }

  // Birthday validation
  isBirthdayValid(birthday) {
    const year = new Date(birthday).getFullYear();
    if (isNaN(year) || year < this.BIRTHDAY_MIN_YEAR) {
      return false;
    }
    return true;
  }

  // Password validation
  isPasswordValid(password) {
    if (
      password.length < this.PASSWORD_MIN_LENGTH ||
      password.length > this.PASSWORD_MAX_LENGTH
    ) {
      return false;
    }
    return true;
  }

  // Password confirmation validation (assuming it needs to match the original password)
  isPasswordConfirmedValid(password, confirmPassword) {
    return password === confirmPassword;
  }
}

export default ValidatorUtils;
