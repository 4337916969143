import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { hcpSelfRegistration } from "utils/handleRequest";
import { toast } from "sonner";
import { Select } from "antd";
import { Modal } from "components/Modal";
import { MdEmail } from "react-icons/md";
import ValidatorUtils from "utils/validatorUtils";
const { Option } = Select;
const validatorUtils = new ValidatorUtils();

const HCPRegistrationForm = ({ brand, token }) => {
  const navigate = useNavigate()

  const [hcp, setHcp] = useState({
    prefix: "Dr.",
    firstName: "",
    lastName: "",
    email: "",
    brand: brand
  });
  brand = brand.charAt(0).toUpperCase() + brand.slice(1);
  const [sendModalStatus, setSendModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const sendInvitation = async () => {
    try {
      if (hcp.firstName === "" || hcp.lastName === "" || hcp.email === "") {
        toast.error("Please fill all the fields");
        return;
      }

      const firstNameValidation = validatorUtils.isFirstNameValid(hcp.firstName);
      if (!firstNameValidation.isValid) {
        toast.error(firstNameValidation.message);
        return;
      }

      const lastNameValidation = validatorUtils.isLastNameValid(hcp.lastName);
      if (!lastNameValidation.isValid) {
        toast.error(lastNameValidation.message);
        return;
      }

      const emailValidation = validatorUtils.isEmailValid(hcp.email);
      if (!emailValidation) {
        return toast.error("Email is not valid!");
      }

      const { success, message } = await hcpSelfRegistration([hcp], token);

      if (success) {
        setSendModalStatus(true)
      } else {
        toast.error(message || "Something went wrong!");
      }
    } catch (error) {
      const serverMessage = error.response?.data?.message;
      const errorMessage =
        serverMessage ||
        error.message ||
        "An error occurred while creating the account.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrefixChange = (value) => {
    setHcp({ ...hcp, prefix: value });
  };

  const handleFirstNameChange = (event) => {
    setHcp({ ...hcp, firstName: event.target.value });
  };

  const handleLastNameChange = (event) => {
    setHcp({ ...hcp, lastName: event.target.value });
  };

  const handleEmailChange = (event) => {
    setHcp({ ...hcp, email: event.target.value.toLowerCase() });
  };
  const openGmail = () => {
    window.open("https://mail.google.com/", "_blank");
  };


  if (sendModalStatus) {
    return (
      <Modal>
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 bg-primary/20 rounded-full flex items-center justify-center mb-4">
            <MdEmail className="w-8 h-8 text-primary" />
          </div>
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828]">
            Check your email
          </p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2 w-1/2 mx-auto">
            We have sent a onboarding magic link to{" "}
            <span className="font-[500]">
              {hcp.email ? hcp.email : "colin@example.com"}
            </span>
          </p>

          <button
            onClick={() => {
              openGmail();
            }}
            type="submit"
            className="font-semibold w-2/4 mx-auto font-Inter text-sm mt-8 mb-2 block rounded-lg transition duration-200 ease-in bg-primary hover:bg-primary/70 py-2.5 text-white"
          >
            Open email app
          </button>

          <p
            onClick={() => {
              navigate("/signin");
            }}
            className="font-Inter cursor-pointer font-[500] text-sm text-[#475467] mt-4 flex items-center justify-center gap-x-3"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3332 7.00008H1.6665M1.6665 7.00008L7.49984 12.8334M1.6665 7.00008L7.49984 1.16675"
                stroke="#475467"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back to log in
          </p>
        </div>
      </Modal>
    );
  }

  return (
    <div className="w-full  overflow-auto p-2 flex flex-col items-center gap-y-3">
      <div className="flex flex-col text-center">
        <h1 className="text-3xl font-semibold">Welcome to {brand} Jawaab Platform</h1>
        <p className="font-base">Please fill these details to create your account</p>
      </div>
      <div className="flex flex-col p-3 ">
        <div className="flex flex-col p-4 rounded-lg">
          <div className="flex flex-row gap-2 w-full">
            <Select
              className="w-1/4 outline-none rounded-lg h-12 mb-2"
              placeholder="Prefix"
              value={hcp.prefix}
              onChange={handlePrefixChange}
            >
              {[" ", "Dr.", "Mr.", "Mrs.", "Ms.", "Prof.", "Mx."].map(
                (prefix) => (
                  <Option className="w-full" value={prefix} key={prefix}>
                    {prefix}
                  </Option>
                )
              )}
            </Select>
            <input
              className="flex-1 w-1/3 outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
              placeholder="First Name"
              value={hcp.firstName}
              maxLength={50}
              onChange={handleFirstNameChange}
            />
          </div>
          <div className="w-full">
            <input
              className="flex-1 w-full outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
              placeholder="Last Name"
              maxLength={50}
              value={hcp.lastName}
              onChange={handleLastNameChange}
            />
          </div>
          <div className="w-full">
            <input
              className="flex-1 w-full outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
              type="email"
              value={hcp.email}
              placeholder="Email"
              maxLength={50}
              onChange={handleEmailChange}
            />
          </div>
        </div>
        <div className="flex self-center items-center justify-between">
          {
            isLoading ?
              <button
                disabled
                type="button"
                className="font-base mt-6 mb-2 block w-full rounded-lg bg-primary py-2.5 text-white"
              >
                <svg
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button> : <button
                onClick={sendInvitation}
                className={`w-full p-2 rounded-lg text-gray-100 font-medium bg-primary`}
              >
                Create Account
              </button>
          }

        </div>
      </div>
    </div>

  );
};

export default HCPRegistrationForm;