import lynparzaSetup from "../brandBook/lynparza/setup.js";
import airsupraSetup from "../brandBook/airsupra/setup.js";

export const getContent = (brand, screen) => {
  if (brand === "lynparza") {
    return lynparzaSetup[screen];
  }
  if (brand === "airsupra") {
    return airsupraSetup[screen];
  }
};
