import React from "react";

const VideoPlayer = ({
  videoRef,
  content,
  togglePlay,
  togglePause,
  muteButton,
  showPauseButton,
  setShowPauseButton,
}) => {
  return (
    <div
      className="relative max-w-[50%] sm:max-w-[40%] md:max-w-[30%] lg:max-w-[20%] xl:max-w-[18%] mx-auto"
      onMouseEnter={() => setShowPauseButton(true)}
      onMouseLeave={() => setShowPauseButton(false)}
    >
      {content.video && (
        <video
          ref={videoRef}
          autoPlay
          loop
          src={content.video}
          playsInline={true}
          onError={(e) => {}}
          className="h-auto max-w-full"
        />
      )}
      {!muteButton ? (
        <button
          onClick={() => togglePlay()}
          className="absolute left-1/2 transform -translate-x-1/2 bottom-2.5 px-2.5 py-1 cursor-pointer border-none bg-white bg-opacity-70 rounded text-sm"
        >
          Click to start
        </button>
      ) : (
        showPauseButton && (
          <button
            onClick={() => togglePause()}
            className="absolute left-1/2 transform -translate-x-1/2 bottom-2.5 px-2.5 py-1 cursor-pointer border-none bg-white bg-opacity-70 rounded text-sm"
          >
            Click to stop
          </button>
        )
      )}
    </div>
  );
};

export default VideoPlayer;
