import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MetricLineChart from "../components/MetricLineChart";
import { getDashboardConversationMetrics } from "../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const ConversationMetric = () => {
  const { admin } = useSelector((state) => state.auth);
  const [questionsAsked, setQuestionsAsked] = useState([]);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [spinner, setSpinning] = useState(true);
  const [followupQuestions, setFollowupQuestions] = useState([]);
  const [metricFilter, setMetricFilter] = useState({
    "Questions Asked": "Last Week",
    "Unanswered Questions": "Last Week",
    "Follow-up Questions": "Last Week",
  });
  const [selectedFilter, setSelectedFilter] = useState({
    metric: "",
    period: "",
  });
  const [currentFilter, setCurrentFilter] = useState({
    "Questions Asked": {
      period: "Last Week",
    },
    "Unanswered Questions": {
      period: "Last Week",
    },

    "Follow-up Questions": {
      period: "Last Week",
    },
  });
  useEffect(() => {
    if (!admin?.brand?.id) return;
    getDashboardConversationMetrics("Last Week", admin?.brand?.id).then(
      ({ success, data }) => {
        if (success) {
          data.data.forEach(({ metric, data }) => {
            let modifiedData = transformData(data, metric);
            if (metric === "Questions Asked") {
              setQuestionsAsked(modifiedData);
            } else if (metric === "Unanswered Questions") {
              setUnansweredQuestions(modifiedData);
            } else if (metric === "Follow-up Questions") {
              setFollowupQuestions(modifiedData);
            }
          });
        }
        setSpinning(false);
      }
    );
  }, [admin]);

  useEffect(() => {
    if (selectedFilter.metric === "" || selectedFilter.period === "") return;
    getDashboardConversationMetrics(
      selectedFilter.period,
      admin?.brand?.id,
      selectedFilter.metric
    ).then(({ success, data }) => {
      if (success) {
        data.data.forEach(({ metric, data }) => {
          let modifiedData = transformData(data, metric);
          if (selectedFilter.metric === "Questions Asked") {
            setQuestionsAsked(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Questions Asked": selectedFilter,
            });
          }
          if (selectedFilter.metric === "Unanswered Questions") {
            setUnansweredQuestions(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Unanswered Questions": selectedFilter,
            });
          }
          if (selectedFilter.metric === "Follow-up Questions") {
            setFollowupQuestions(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Follow-up Questions": selectedFilter,
            });
          }
        });
      }
    });
  }, [selectedFilter]);

  function transformData(input, metric) {
    return input.map((item) => ({
      date: item.date,
      [metric]: item.value,
    }));
  }
  return (
    <Spin
      tip="Loading Conversation Metrics..."
      size="large"
      fullscreen={false}
      spinning={spinner}
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
        />
      }
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-[33%]">
          <MetricLineChart
            title={"Questions Asked"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={questionsAsked}
            category={["Questions Asked"]}
            color={"blue"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Questions Asked"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"Unanswered Questions"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={unansweredQuestions}
            category={["Unanswered Questions"]}
            color={"red"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Unanswered Questions"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"Follow-up Questions"}
            chartdata={followupQuestions}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            category={["Follow-up Questions"]}
            color={"indigo"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Follow-up Questions"]}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ConversationMetric;
