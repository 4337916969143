import React from "react";

const InstructionVideo = ({ videoSrc, videoRef, setLoader }) => {
  return (
    <div className="flex justify-center items-center">
      <video
        className="w-full"
        src={videoSrc}
        ref={videoRef}
        onCanPlay={() => setLoader(false)}
        autoPlay
        muted={false}
        playsInline
        onError={(e) => {}}
        onClick={() => videoRef.current.play()}
      />
    </div>
  );
};

export default InstructionVideo;
