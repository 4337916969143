import React from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";

const HCPAuthentication = () => {
  const navigate = useNavigate();

  return (
    <div className="grid min-h-screen place-items-center">
      <div className="sm:max-w-sm xs:max-w-xm w-full">
        <p className="text-3xl text-center font-Inter font-semibold text-[#101828] ease-in  duration-300 mt-2">
          Log in to your account
        </p>

        <p className="font-Inter text-center text-[#101828] mt-1">
          Welcome back! Please enter your details.
        </p>
        <div className="flex align-center justify-center text-center  mt-5 md:mx-[-10px]">
        <p className="font-[500] cursor-pointer font-Inter text-md">
          <span className="block sm:inline">Never created a login yet?</span>{" "}
          <span
            className="font-[500] text-primary2 cursor-pointer font-Inter text-md block sm:inline"
            onClick={() => {
              navigate("/login-creation");
            }}
          >
            Click here to set one up
          </span>
        </p>
      </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default HCPAuthentication;
