import { useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import { verifyInvitation } from "utils/handleRequest";
import { toast } from "sonner";
import jawaab from "assets/images/jawaab_latest.png";
import lynparzaLogo from "assets/images/lynparza-logo-s.jpg";
import airsupraLogo from "assets/images/airsupra-logo.svg";
import HCPRegistrationForm from "./components/HCPForm";
import Loader from "components/Loader";


const Invitation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const brand = urlParams.get("brand");
    const token = urlParams.get("token");
    const [loading,setLoading]=useState(true);
    const [message,setMessage]=useState({
        type:"",
        message:""
    });

    useEffect(() => {
        verifyInvitation(brand, token).then((resp) => {
            if(resp.success){
                toast.success("Invitation verified successfully");
                setMessage({
                    type:"success",
                    message:resp.message
                });
            }else{
                setMessage({
                    type:"error",
                    message:resp.message
                });
            }
        }).catch((err) => {
            setMessage({
                type:"error",
                message:err.message
            });
        }).finally(()=>{
            setLoading(false);
        });
    }, [brand, token]);




const getBrandLogo=()=>{
    if(brand ==="lynparza"){
        return lynparzaLogo;
    }
    else if(brand ==="airsupra"){
        return airsupraLogo;
    }
    else{
        return jawaab;
    }
    
}
if(loading){
    return <div className="absolute backdrop-blur-sm w-full h-full z-20">
          <Loader />
        </div>
}

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            {message.type === "error" && (
                <div className="flex flex-col items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 text-red-500 mb-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                        />
                    </svg>
                    <p className="text-xl text-red-500">{message.message}</p>
                </div>
            )}

            {
                message.type==="success" && (
                    <div className="flex flex-col items-center">
                        <img src={getBrandLogo()} alt="brand" style={{ width: "12rem", height: "auto", marginBottom: "2rem" }}
          className="text-center mx-auto"/>
                        <HCPRegistrationForm brand={brand} setIsLoading={setLoading} token={token} />
                    </div>
                )
            }
        </div>
    )
}

export default Invitation;