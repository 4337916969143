const initState = {
  bgColor: "bg-primary1",
  textColor: "text-primary1",
  lightbgColor: "bg-primary-light1",
  companyName: "valuedo",
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_COLOR":
      return {
        ...state,
        bgColor: action.payload.bgColor,
        textColor: action.payload.textColor,
        lightbgColor: action.payload.lightbgColor,
        companyName: action.payload.companyName,
      };
    default:
      return state;
  }
};
