const initState = {
  conversation: false,
  requestLoader: false,
  question: "",
  listening: false,
  response: {
    text: "",
    image: [""],
    video: "",
  },
  greetingVideo: null,
  videoSilent: true,
  descriptionTitle: "",
  showMSL: false,
  history: [],
  followup: false,
  showFollowupQuestion: false,
  loader: true,
  personalizedQuest: null,
  assistantQN: "",
  playing: false,
  brand: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_CONVERSATION":
      return {
        ...state,
        conversation: action.payload,
      };
    case "SET_REQUEST_LOADER":
      return {
        ...state,
        requestLoader: action.payload,
      };
    case "SET_QUESTION":
      return {
        ...state,
        question: action.payload,
      };
    case "SET_RESPONSE":
      return {
        ...state,
        response: action.payload,
      };
    case "SET_VIDEO_SILENT":
      return {
        ...state,
        videoSilent: action.payload,
      };
    case "SET_DESCRIPTION_TITLE":
      return {
        ...state,
        descriptionTitle: action.payload,
      };
    case "SET_LISTENING":
      return {
        ...state,
        listening: action.payload,
      };
    case "SET_SHOW_MSL":
      return {
        ...state,
        showMSL: action.payload,
      };
    case "SET_GREETING_VIDEO":
      return {
        ...state,
        greetingVideo: action.payload,
      };
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    case "SET_FOLLOWUP":
      return {
        ...state,
        followup: action.payload,
      };
    case "SET_SHOW_FOLLOWUP_QUESTION":
      return {
        ...state,
        showFollowupQuestion: action.payload,
      };
    case "SET_LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "SET_PERSONALIZED_QUEST":
      return {
        ...state,
        personalizedQuest: action.payload,
      };
    case "SET_ASSISTANT_QN":
      return {
        ...state,
        assistantQN: action.payload,
      };
    case "SET_PLAYING":
      return {
        ...state,
        playing: action.payload,
      };
    case "SET_BRAND":
      return {
        ...state,
        brand: action.payload,
      };
    default:
      return state;
  }
};
