import axios from "axios";
import { getCookie, setCookie, clearBrandCookies } from "../../../utils/cookie";
const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

//request interceptor to add the auth token header to requests
adminInstance.interceptors.request.use(
  (config) => {
    const accessToken = getCookie("brandAccessToken");
    const accessHeader = `Bearer ${accessToken}`;
    if (accessToken) {
      config.headers["Authorization"] = accessHeader;
      return config;
    } else {
      return Promise.reject("No token found");
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error
adminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    let refreshToken = getCookie("brandRefreshToken");
    if (
      refreshToken &&
      (error.response.status === 500 || error.response.status === 504) &&
      !originalRequest.err_retry
    ) {
      originalRequest.err_retry = true;
      // retry the request using instance
      return adminInstance(originalRequest);
    }
    if (
      refreshToken &&
      error.response.status === 401 &&
      (!originalRequest._retry || originalRequest._retry === undefined)
    ) {
      originalRequest._retry = true;
      return axios
        .post(process.env.REACT_APP_BASE_URL + `/brand/refresh-token`, {
          refreshToken: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            setCookie(
              "brandAccessToken",
              res.data.accessToken,
              res.data.accessTokenExpiry
            );
            return adminInstance(originalRequest);
          }
        })
        .catch((err) => {
          clearBrandCookies();
          window.location.href = "/signin";
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

export default adminInstance;
