import React, { useEffect, useState } from "react";
import ChooseConfigurationContent from "./features/ChooseConfigurationContent";
import useAvatarSelection from "./modules/useAvatarSelection";
import useVideoLoader from "./modules/useVideoLoader";
import { useSelector } from "react-redux";
import { getScript } from "../../utils/handleRequest";
import { setCookie } from "utils/cookie";
import { set } from "date-fns";

const Configuration = () => {
  const expiryDate = set(new Date(), { years: new Date().getFullYear() + 1 });
  setCookie("firstTime", true, expiryDate);
  setCookie("voiceMode", true, expiryDate);
  const user = useSelector((state) => state.auth.user);
  const [value, setValue] = useState(1);
  const onChange = (e) => setValue(e.target.value);
  const setVoiceMode = (checked) => setCookie("voiceMode", checked, expiryDate);
  const { videoRef, loader, setLoader } = useVideoLoader();
  const { selectedAvatar, handleCardClick } = useAvatarSelection();

  const [content, setContent] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getContent = async () => {
      const content = await getScript("avatarConfiguration", user?.brand);

      setContent(content.data);
    };

    getContent();
  }, [user]);

  return (
    <ChooseConfigurationContent
      content={content}
      videoRef={videoRef}
      loader={loader}
      setLoader={setLoader}
      selectedAvatar={selectedAvatar}
      handleCardClick={handleCardClick}
      onChange={onChange}
      value={value}
      setVoiceMode={setVoiceMode}
    />
  );
};

export default Configuration;
