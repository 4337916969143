import React from "react";

const AvatarCard = ({ avatar, handleCardClick, selectedAvatar }) => {
  return (
    <div
      onClick={() => handleCardClick(avatar.name)}
      className={`cursor-pointer border ${
        selectedAvatar === avatar.name
          ? "border-primary bg-primary/10"
          : "border-gray-300"
      } rounded-lg bg-gray-100 p-2.5 w-[calc(33.333%-20px)] flex flex-col items-center text-center`}
    >
      <img
        src={avatar.url}
        alt={avatar.name}
        className="w-full h-auto mb-2.5"
      />
      <span>{avatar.name}</span>
    </div>
  );
};

export default AvatarCard;
