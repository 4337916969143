import React from "react";

const Loader = () => {
  return (
    <div className="animate-fade-in-1s flex flex-col justify-between items-center text-center h-[calc(100vh-50px)] py-5">
      <div className="backdrop-blur-md bg-gray-800 opacity-80 h-full w-full flex items-center justify-center">
        <img
          alt="loader"
          src="/images/ring_loader.svg"
          className="w-20 h-20 text-gray-100"
        />
      </div>
    </div>
  );
};

export default Loader;
