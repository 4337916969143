import React, { useEffect, useState } from "react";
import { getScript } from "../utils/handleRequest";
import Loader from "../components/Loader";
import { Button, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CiShare1 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import annyang from "annyang";
import { toast } from "sonner";
import instance from "../redux/actions/AxiosInstance/authenticated";
import log from "../utils/log";
import { clearHCPCookies } from "../utils/cookie";

const iconMap = {
  IoIosArrowRoundBack: IoIosArrowRoundBack,
  CiShare1: CiShare1,
};

const HelpMenu = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [optOUTmodal, setOptOUTmodal] = useState(false);
  const [optOutLoader, setOptOutLoader] = useState(false);
  const [helpMenuData, setHelpMenuData] = useState(null);
  const [optOutModalData, setOptOutModalData] = useState(null);

  useEffect(() => {
    annyang.abort();
    const fetchData = async () => {
      try {
        setLoader(true);
        const script = await getScript("helpMenu");
        const { success, data } = script;
        if (!success) {
          return;
        }
        setHelpMenuData(data.helpMenu);
        setOptOutModalData(data.optOutConfirmationModal);
      } catch (error) {
        log("error", "Error in fetching help menu data", error.message);
      } finally {
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  if (loader && !helpMenuData) {
    return (
      <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
        <Loader />
      </div>
    );
  }

  const handleAction = async (action) => {
    if (action === "logOut") {
      await logOut();
      return;
    }
    if (action === "optOut") {
      setOptOUTmodal(true);
      return;
    }
    if (action === "optOutConfirmed") {
      await optOut();
      return;
    }
    navigate(`/${action}`);
  };
  const logOut = async () => {
    try {
      setLoader(true);
      const { data } = await instance.get("/user/logout");
      if (data.success) {
        clearHCPCookies();
        navigate("/signin");
        window.location.reload(true);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setLoader(false);
    }
  };

  const BackIcon = iconMap[helpMenuData.backButton.icon];

  const optOut = async () => {
    try {
      setOptOutLoader(true);
      const { data } = await instance.get("/user/opt-out");
      if (data.success) {
        toast.success(data.message);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        clearHCPCookies();
        navigate("/signin");
      }
    } catch (error) {
      log("error", "Error in opt-out", error.message);
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setOptOutLoader(false);
      setOptOUTmodal(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 mt-8">
      <Modal
        title={optOutModalData?.heading}
        open={optOUTmodal}
        onOk={() =>
          handleAction(
            optOutModalData?.options.buttons.find(
              (button) => button.key === "yes"
            )?.action
          )
        }
        okButtonProps={{
          style: { backgroundColor: "white", color: "red" },
        }}
        closable={false}
        onCancel={() => setOptOUTmodal(false)}
        okText={
          optOutLoader ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 20,
                  }}
                />
              }
            />
          ) : (
            optOutModalData?.options.buttons.find(
              (button) => button.key === "yes"
            )?.text
          )
        }
      >
        <p>{optOutModalData?.subHeading}</p>
      </Modal>
      <div className="bg-background-subtle border border-gray-300 rounded-lg p-4 px-8 text-center w-full max-w-2xl">
        <h1 className="text-2xl font-bold text-gray-800">
          {helpMenuData.heading}
        </h1>
        <p className="text-lg text-gray-600 mt-2">{helpMenuData.subHeading}</p>
        <div className="flex justify-center mt-4">
          <Button
            className="bg-gray-500 text-white p-4 flex items-center"
            size="large"
            icon={<BackIcon className="text-2xl" />}
            onClick={() => handleAction(helpMenuData.backButton.action)}
          >
            {helpMenuData.backButton.text}
          </Button>
        </div>
      </div>

      <div className="flex justify-between w-full max-w-4xl mt-8">
        <div className="flex flex-col space-y-4 bg-background-subtle border border-gray-300 rounded-lg p-8 w-full max-w-xs">
          {helpMenuData.options.buttons
            .filter((button) => button.position === "left")
            .map((button, index) => {
              const Icon = iconMap[button.icon];
              return (
                <Button
                  key={button.key}
                  className={`${
                    button.style === "primary"
                      ? "bg-gray-100 text-black p-4 flex items-center"
                      : ""
                  }`}
                  onClick={() => handleAction(button.action)}
                  icon={<Icon className="text-2xl" />}
                >
                  {button.text}
                </Button>
              );
            })}
        </div>
        <div className="flex flex-col space-y-4 bg-background-subtle border border-gray-300 rounded-lg p-8 w-full max-w-xs">
          {helpMenuData.options.buttons
            .filter((button) => button.position === "right")
            .map((button, index) => {
              const Icon = iconMap[button.icon];
              return (
                <Button
                  key={button.key}
                  className={`${
                    button.style === "primary"
                      ? "bg-gray-100 text-black p-4 flex items-center"
                      : ""
                  }`}
                  onClick={() => handleAction(button.action)}
                  icon={<Icon className="text-2xl" />}
                >
                  {button.text}
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HelpMenu;
