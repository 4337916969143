import React, { useState } from "react";
import { connect } from "react-redux";
import StatCard from "../components/StatCard";

import { HCPTable } from "../components/HCPTable";

import { useSelector } from "react-redux";
import { RiUserAddLine } from "react-icons/ri";
import InviteModal from "../components/InviteModal";
import UserMetric from "../components/userMetric";
import SessionMetric from "../components/sessionMetric";
import ConversationMetric from "../components/conversationMetric";
import FrequentlyAskedQuestions from "../components/FAQSession";


export const Dashboard = ({ admin }) => {
  const { textColor } = useSelector((state) => state.colors);

  const [showInviteModal, setShowInviteModal] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      {showInviteModal && (
        <InviteModal handleClose={() => setShowInviteModal(false)} />
      )}

      <div
        className="py-5 border-b"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "3rem",
        }}
      >
        <p className="title text-3xl px-8">
          {capitalizeFirstLetter(admin.brand.brandName)}
        </p>
        {/* invite button */}
        <button
          className={`flex ${textColor} items-center bg-white rounded-lg shadow-sm px-5 py-2 gap-x-2 hover:shadow-md border border-gray-200`}
          onClick={() => setShowInviteModal(true)}
        >
          <RiUserAddLine /> Invite HCPs
        </button>
      </div>
      <div className="flex flex-col px-8 py-5 gap-y-5">
        <div className="flex flex-col">
          <h1 className="text-3xl font-semibold">Welcome back, {admin.name}</h1>
          <p className="subtitle">
            Track, manage and forecast brand and doctors.
          </p>
        </div>
        <StatCard />

        <UserMetric />
        <SessionMetric />
        <ConversationMetric />
        <HCPTable />
        <FrequentlyAskedQuestions />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
