import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import { getQuickStat } from "../redux/actions/auth";
import { Skeleton } from "antd";

const StatCard = () => {
  const { admin } = useSelector((state) => state.auth);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!admin?.brand?.id) return;
    getQuickStat(admin?.brand?.id).then(({ success, data }) => {
      if (success) {
        setStats(data.data);
        setLoading(false);
      }
    });
  }, [admin]);

  return (
    <div className="flex items-center justify-between">
      {loading
        ? Array(4)
            .fill()
            .map((_, i) => (
              <div
                key={i}
                className="border border-gray-200 rounded-lg p-4 w-[23%] flex flex-col gap-y-2 shadow"
              >
                <Skeleton active />
              </div>
            ))
        : stats.map((stat, i) => (
            <div
              key={i}
              className="border border-gray-200 rounded-lg p-4 w-[23%] flex flex-col gap-y-2 shadow"
            >
              <p className="text-gray-700">{stat.title}</p>
              <h1 className="text-3xl font-medium">{stat.value}</h1>
              <div className="flex items-center gap-x-1">
                <p
                  className={`flex items-center ${
                    stat.increase ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {stat.increase ? (
                    <IoIosArrowRoundUp className="text-green-500" />
                  ) : (
                    <IoIosArrowRoundDown className="text-red-500" />
                  )}
                  {stat.percentage}
                </p>
                <p className="text-gray-500 text-sm">vs last month</p>
              </div>
            </div>
          ))}
    </div>
  );
};

export default StatCard;
