import { BarChart } from "@tremor/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDashboardSessionMetrics } from "../redux/actions/auth";

const chartdata = [
  {
    day: "Monday",
    Minutes: 2,
  },
  {
    day: "Tuesday",
    Minutes: 5,
  },
  {
    day: "Wednesday",
    Minutes: 10,
  },
  {
    day: "Thursday",
    Minutes: 6,
  },
  {
    day: "Friday",
    Minutes: 5,
  },
  {
    day: "Saturday",
    Minutes: 3,
  },
  {
    day: "Sunday",
    Minutes: 5,
  },
];

const chartButtons = ["Last Week", "Last Month", "3 Months", "1 Year"];

const DoctorBarChart = ({ userId }) => {
 
  const [activeButton, setActiveButton] = useState("");
  const { admin } = useSelector((state) => state.auth);
  const [timeSpent, setTimeSpent] = useState([]);

  const { textColor } = useSelector((state) => state.colors);
  useEffect(() => {
    if (!admin?.brand?.id) return;
    getDashboardSessionMetrics(
      "Last Week",
      admin?.brand?.id,
      "Total Session Time",
      userId
    ).then(({ success, data }) => {
      if (success) {
        data?.data?.forEach(({ metric, data }) => {
          let modifiedData = transformData(data);
          if (metric === "Total Session Time") setTimeSpent(modifiedData);
        });
      }
    });
  }, [admin]);

  function transformData(input) {
    return input.map((item) => ({
      day: item.date,
      Minutes: item.value,
    }));
  }

  useEffect(() => {
    if (activeButton === "") return;
    getDashboardSessionMetrics(activeButton, admin?.brand?.id, "", userId).then(
      ({ success, data }) => {
        if (success) {
          data.data.forEach(({ metric, data }) => {
            let modifiedData = transformData(data, metric);
            if (metric === "Total Session Time") setTimeSpent(modifiedData);
          });
        }
      }
    );
  }, [activeButton]);

  return (
    <div className="p-5 border border-gray-200 rounded-lg flex flex-col gap-y-3">
      <div className="flex items-center justify-between p-4">
        <div className="flex flex-col gap-x-2">
          <p className="text-xl font-semibold text-gray-700">
            Total Time Spent
          </p>
          <p className="subtitle">Total Minutes you spent on the platform</p>
        </div>
        <div className="flex gap-x-2">
          {chartButtons.map((e, i) => {
            return (
              <button
                className={`px-4 py-1 font-medium rounded-lg border border-gray-200 ${
                  activeButton === e ||
                  (activeButton === "") & (e === "Last Week")
                    ? `${textColor} `
                    : "text-gray-700"
                }`}
                onClick={() => setActiveButton(e)}
              >
                {e}
              </button>
            );
          })}
        </div>
      </div>
      <BarChart
        className="h-72 mt-4 w-full"
        data={timeSpent}
        index="day"
        categories={["Minutes"]}
        colors={["blue"]}
        yAxisWidth={56}
        showLegend={false}
        minValue={0}
      />
    </div>
  );
};
export default DoctorBarChart;
