import { getScript } from "../utils/handleRequest";
export async function fetchContent(screen, brand) {
  try {
    const script = await getScript(screen, brand);
    if (!script.success) {
      return { success: false, content: "No content found" };
    }

    return { success: true, content: script?.data };
  } catch (error) {
    console.error("Error:", error);
    return { success: false, content: "No content found" };
  }
}
