// OptOutModal.js
import React from "react";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const OptOutModal = ({ open, onOk, loading, onCancel }) => {
  return (
    <Modal
      title="Are you sure you want to opt out from Jawaab?"
      open={open}
      onOk={onOk}
      okButtonProps={{
        style: { backgroundColor: "white", color: "red" },
      }}
      closable={false}
      onCancel={onCancel}
      okText={
        loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 20,
                }}
              />
            }
          />
        ) : (
          "Yes, opt me out"
        )
      }
    >
      <p>This is invite only, all your data will be removed.</p>
    </Modal>
  );
};

export default OptOutModal;
