export const setCookie = (name, value, expiry) => {
  let expires = "; expires=" + expiry;
  document.cookie = name + "=" + value + expires + "; path=/; Secure";
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};


export const clearCookie = (name, path = "/", domain) => {
  let cookieString = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path};`;
  if (domain) {
    cookieString += `domain=${domain};`;
  }
  document.cookie = cookieString;
};

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const clearHCPCookies = () => {
  clearCookie("accessToken");
  clearCookie("refreshToken");
};

export const clearBrandCookies = () => {
  clearCookie("brandAccessToken");
  clearCookie("brandRefreshToken");
};