const setup = {
  welcome: {
    screen: "Welcome",
    title: "Welcome to AZ aOC HCP Concierge",
    subtitle:
      "Where we can answer your disease state, guidelines and LYNPARZA questions",
    description: "",
    video:
      "https://res.cloudinary.com/ds80j9gac/video/upload/v1724858491/welcome_astrazeneca_3.mp4",
    button: "Next",
  },
};

export default setup;
