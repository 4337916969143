import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDashboardFAQ } from "../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const FrequentlyAskedQuestions = () => {
  const [mostAsked, setMostAsked] = useState([]);
  const { admin } = useSelector((state) => state.auth);
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    if (!admin?.brand?.id) return;
    getDashboardFAQ(admin?.brand?.id).then(({ data }) => {
      if (data.success) {
        setMostAsked(data.data);
      }
      setSpinning(false);
    });
  }, [admin]);
  return (
    <Spin
      tip="Loading Frequently Asked Questions..."
      size="large"
      fullscreen={false}
      spinning={spinning}
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
        />
      }
    >
      <div className="w-full flex flex-col gap-y-3">
        <h2 className="title">Frequently Asked Questions</h2>
        <div className="flex items-center flex-wrap gap-x-4 gap-y-3 p-4 rounded-lg border border-gray-300">
          {mostAsked.length === 0 ? (
            <div className="w-full flex justify-center">
              <p className="text-gray-500 text-center">
                No frequently asked questions found
              </p>
            </div>
          ) : (
            mostAsked.map((question, i) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="bg-gray-100 rounded-lg p-3 border border-gray-200 w-[30%]"
                key={i}
              >
                <p>{question.question}</p>
                <p style={{ fontSize: ".7rem", alignSelf: "end" }}>
                  {question.count}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </Spin>
  );
};

export default FrequentlyAskedQuestions;
