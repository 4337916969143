import React from "react";
import AvatarCard from "./AvatarCard";

const AvatarSelection = ({ avatars, handleCardClick, selectedAvatar= 'Jonathan' }) => {
  return (
    <div className="flex flex-col items-center w-full h-3/6">
      <p className="font-normal text-gray-600 dark:text-gray-400 self-start px-5">
        {avatars?.title}
      </p>
      <div className="flex flex-wrap justify-center gap-2 w-full overflow-y-auto mt-4">
        {avatars?.options.map((avatar, index) => (
          <AvatarCard
            key={index}
            avatar={avatar}
            handleCardClick={handleCardClick}
            selectedAvatar={selectedAvatar || 'Jonathan'}
          />
        ))}
      </div>
    </div>
  );
};

export default AvatarSelection;
