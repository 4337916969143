import React, { useContext, useRef, useEffect, useState } from "react";
import { Button } from "antd";
import AvatarContext from "./AvatarContext";
import { DownloadOutlined } from "@ant-design/icons";

const Answer = ({ options }) => {
  const divRef = useRef(null);
  const { response, descriptionTitle } = useContext(AvatarContext);
  const [scrollHeight, setScrollHeight] = useState(0);

  const fullText = response?.long_answer || "";
  const image = response?.image?.[0] || "";

  useEffect(() => {
    if (divRef.current) {
      setScrollHeight(divRef.current.scrollHeight);
    }
  }, [fullText]);

  const downloadButton = options?.find((b) => b.key === "downloadButton");
  const learnMoreButton = options?.find((b) => b.key === "learnMoreButton");

  return (
    <div className="flex flex-col md:mt-4 mt-16">
      {descriptionTitle && (
        <div className="flex justify-center items-center px-1">
          <h1 className="text-xl font-semibold text-gray-800 text-center break-words hyphens-auto w-full md:max-w-[calc(100%-200px)]">
            {descriptionTitle}
          </h1>
        </div>
      )}
      <div className="flex flex-col bg-gray-100 p-4 border border-gray-300 rounded-lg mt-4">
        {response?.short_answer && (
          <h2
            className="text-gray-500 italic text-center px-4 py-2 mx-auto animate-fade-in-1s"
            data-intro="Look for the brief reply from avatar in this space"
          >
            "{response.short_answer}"
          </h2>
        )}
        <div className="flex flex-wrap justify-evenly min-h-[18rem]">
          {image && (
            <img
              className="md:w-2/5 w-full rounded-lg object-contain pr-3"
              data-intro="Look for the image/graph in this space"
              src={image}
              alt="Response"
            />
          )}
          {/* <div
            ref={divRef}
            data-intro="Find the in-depth explanation within this section."
            className={`md:w-3/5 w-full my-5 text-sm text-gray-600 animate-fade-in-1s ${
              scrollHeight > 500 ? "overflow-y-scroll" : "overflow-auto"
            }`}
            style={{ maxHeight: "29rem" }}
            dangerouslySetInnerHTML={{ __html: fullText }}
          ></div> */}
        </div>
        <div className="flex self-end mt-4">
          {downloadButton && (
            <a
              href={image || downloadButton.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                data-intro="Download the extra details as a PDF."
                className="mt-4 mr-4"
                shape="round"
                icon={<DownloadOutlined />}
              >
                {downloadButton.text}
              </Button>
            </a>
          )}
          {learnMoreButton && (
            <a
              href={learnMoreButton.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                data-intro="For more insights, click here."
                data-position="left"
                shape="round"
                className="mt-4 bg-primary text-white"
              >
                {learnMoreButton.text}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Answer;
