import React from "react";
import { Radio } from "antd"; // Assuming you're using Ant Design for Radio

const AccentSelection = ({ accents, onChange, value }) => {
  return (
    <div className="mt-4 px-5 self-start h-1/5">
      <p className="font-normal text-gray-600 dark:text-gray-400">
        {accents?.title}
      </p>
      <div className="mt-4">
        <Radio.Group
          onChange={onChange}
          value={value}
          className="grid grid-cols-2 gap-4"
        >
          {accents?.options.map((accent) => (
            <Radio
              key={accent?.value}
              className="p-4 bg-gray-100 rounded-lg border border-gray-300"
              value={accent?.value}
            >
              {accent?.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default AccentSelection;
