import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MetricLineChart from "../components/MetricLineChart";
import { getDashboardSessionMetrics } from "../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const SessionMetric = () => {
  const { admin } = useSelector((state) => state.auth);
  const [longestSessionTime, setLongestSessionTime] = useState([]);
  const [lowestSessionTime, setLowestSessionTime] = useState([]);
  const [averageSessionTime, setAverageSessionTime] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [metricFilter, setMetricFilter] = useState({
    "Longest Session Time": "Last Week",
    "Lowest Session Time": "Last Week",
    "Average Session Time": "Last Week",
  });
  const [selectedFilter, setSelectedFilter] = useState({
    metric: "",
    period: "",
  });
  const [currentFilter, setCurrentFilter] = useState({
    "Longest Session Time": {
      period: "Last Week",
    },
    "Lowest Session Time": {
      period: "Last Week",
    },

    "Average Session Time": {
      period: "Last Week",
    },
  });
  useEffect(() => {
    if (!admin?.brand?.id) return;
    getDashboardSessionMetrics("Last Week", admin?.brand?.id).then(
      ({ success, data }) => {
        if (success) {
          data?.data?.forEach(({ metric, data }) => {
            let modifiedData = transformData(data, metric);
            if (metric === "Longest Session Time") {
              setLongestSessionTime(modifiedData);
            } else if (metric === "Lowest Session Time") {
              setLowestSessionTime(modifiedData);
            } else if (metric === "Average Session Time") {
              setAverageSessionTime(modifiedData);
            }
          });
        }
        setSpinner(false);
      }
    );
  }, [admin]);

  useEffect(() => {
    if (selectedFilter.metric === "" || selectedFilter.period === "") return;
    getDashboardSessionMetrics(
      selectedFilter.period,
      admin?.brand?.id,
      selectedFilter.metric
    ).then(({ success, data }) => {
      if (success) {
        data.data.forEach(({ metric, data }) => {
          let modifiedData = transformData(data, metric);
          if (selectedFilter.metric === "Longest Session Time") {
            setLongestSessionTime(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Longest Session Time": selectedFilter,
            });
          }
          if (selectedFilter.metric === "Lowest Session Time") {
            setLowestSessionTime(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Lowest Session Time": selectedFilter,
            });
          }
          if (selectedFilter.metric === "Average Session Time") {
            setAverageSessionTime(modifiedData);
            setCurrentFilter({
              ...currentFilter,
              "Average Session Time": selectedFilter,
            });
          }
        });
      }
    });
  }, [selectedFilter]);

  function transformData(input, metric) {
    return input.map((item) => ({
      date: item.date,
      [metric]: item.value,
    }));
  }
  return (
    <Spin
      tip="Loading Session Metrics..."
      size="large"
      fullscreen={false}
      spinning={spinner}
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
        />
      }
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-[33%]">
          <MetricLineChart
            title={"Longest Session Time"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={longestSessionTime}
            category={["Longest Session Time"]}
            color={"green"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Longest Session Time"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"Lowest Session Time"}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            chartdata={lowestSessionTime}
            category={["Lowest Session Time"]}
            color={"yellow"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Lowest Session Time"]}
          />
        </div>
        <div className="w-[33%]">
          <MetricLineChart
            title={"Average Session Time"}
            chartdata={averageSessionTime}
            metricFilter={metricFilter}
            setMetricFilter={setMetricFilter}
            category={["Average Session Time"]}
            color={"cyan"}
            showButtons={true}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={currentFilter["Average Session Time"]}
          />
        </div>
      </div>
    </Spin>
  );
};

export default SessionMetric;
