// EditProfileModal.js
import React from "react";
import { Modal, Select, Input } from "antd";
import { FaUserEdit } from "react-icons/fa";
const { Option } = Select;

const EditProfileModal = ({ open, onCancel, hcp, setHcp, onOk }) => {
  return (
    <Modal
      className="w-full max-w-md shadow-md rounded-lg"
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <div className="p-6 flex flex-col items-center space-y-4">
        <div className="w-14 h-14 bg-yellow-100 rounded-full flex items-center justify-center">
          <FaUserEdit className="w-8 h-8 text-yellow-500" />
        </div>
        <h2 className="text-xl font-semibold">Edit Personal Information</h2>
        <p className="text-sm text-gray-500 text-center">
          Update your personal details below.
        </p>
      </div>
      <div className="p-6 space-y-4">
        <div className="space-y-2">
          <label
            htmlFor="prefix"
            className="block text-sm font-medium text-gray-700"
          >
            Prefix
          </label>
          <Select
            className="w-full border border-gray-300 rounded-lg h-[2.5rem] mb-2"
            placeholder="Prefix"
            value={hcp.prefix}
            onChange={(value) => setHcp({ ...hcp, prefix: value })}
          >
            {[" ", "Dr.", "Mr.", "Mrs.", "Ms.", "Prof.", "Mx."].map(
              (prefix) => (
                <Option className="w-full py-5" value={prefix} key={prefix}>
                  {prefix}
                </Option>
              )
            )}
          </Select>
        </div>
        <div className="space-y-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <Input
            className="w-full border border-gray-300 rounded-lg px-3 py-2 mb-2"
            placeholder="First Name"
            value={hcp.firstName}
            maxLength="50"
            onChange={(e) => setHcp({ ...hcp, firstName: e.target.value })}
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <Input
            className="w-full border border-gray-300 rounded-lg px-3 py-2 mb-2"
            placeholder="Last Name"
            maxLength="50"
            value={hcp.lastName}
            onChange={(e) => setHcp({ ...hcp, lastName: e.target.value })}
          />
        </div>
        <div className=" flex justify-end">
          <button
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={() => onOk()}
          >
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
