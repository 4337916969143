import React, { useState } from "react";
import { Modal } from "./Modal";
import { MdOutlineClose } from "react-icons/md";
import { IoPersonAddOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { Select } from "antd";
import adminInstance from "../redux/actions/AxiosInstance/pharmaAuthenticated";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { GoQuestion } from "react-icons/go";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MdDeleteOutline } from "react-icons/md";
import ValidatorUtils from "../utils/validatorUtils";

const { Dragger } = Upload;
const { Option } = Select;
const validatorUtils = new ValidatorUtils();

const InviteModal = ({ handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { admin } = useSelector((state) => state.auth);
  const { bgColor, textColor, lightbgColor } = useSelector(
    (state) => state.colors
  );

  const [hcpList, setHcpList] = useState([
    { prefix: "Dr.", firstName: "", lastName: "", email: "" },
  ]);

  const sendInvitation = async () => {
    setIsLoading(true);
    try {
      if (
        (hcpList[0].firstName === "" && hcpList[0].lastName === "") ||
        hcpList[0].email === ""
      ) {
        toast.error("Please fill all the fields");
        return;
      }

      for (let i = 0; i < hcpList.length; i++) {
        const hcp = hcpList[i];

        const firstNameValidation = validatorUtils.isFirstNameValid(
          hcp.firstName
        );
        if (!firstNameValidation.isValid) {
          toast.error(firstNameValidation.message);
          return;
        }

        const lastNameValidation = validatorUtils.isLastNameValid(hcp.lastName);
        if (!lastNameValidation.isValid) {
          toast.error(lastNameValidation.message);
          return;
        }

        const emailValidation = validatorUtils.isEmailValid(hcp.email);
        if (!emailValidation) {
          return toast.error("Email is not valid!");
        }
      }

      const response = await adminInstance.post("/user/invite", {
        list: hcpList,
        companyName: "Jawaab",
        companylogo: "https://i.ibb.co/0BvJ4LR/newjaavab.png",
      });

      const { success, message, data } = response.data;

      if (success) {
        let close = true;
        for (let each in data) {
          if (data[each].success === true) {
            toast.success(data[each].message);
            setHcpList(hcpList.filter((hcp) => hcp.email !== data[each].email));
          } else {
            toast.error(data[each].message);
            close = false;
          }
          if (close) {
            handleClose();
          }
        }
      } else {
        toast.error(message || "Invitation failed!");
      }
    } catch (error) {
      const serverMessage = error.response?.data?.message;
      const errorMessage =
        serverMessage ||
        error.message ||
        "An error occurred while sending the invitation.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrefixChange = (value, index) => {
    const updatedList = [...hcpList];
    updatedList[index].prefix = value;
    setHcpList(updatedList);
  };

  const handleFirstNameChange = (event, index) => {
    const updatedList = [...hcpList];
    updatedList[index].firstName = event.target.value;
    updatedList[index].brand = admin?.brand?.brandName;
    setHcpList(updatedList);
  };

  const handleLastNameChange = (event, index) => {
    const updatedList = [...hcpList];
    updatedList[index].lastName = event.target.value;
    setHcpList(updatedList);
  };

  const handleEmailChange = (event, index) => {
    const updatedList = [...hcpList];
    updatedList[index].email = event.target.value.toLowerCase();
    setHcpList(updatedList);
  };

  const handleRemove = (index) => {
    const updatedList = [...hcpList];
    updatedList.splice(index, 1);
    setHcpList(updatedList);
  };

  const handleAddMore = () => {
    setHcpList([
      ...hcpList,
      { prefix: "Dr.", firstName: "", lastName: "", email: "" },
    ]);
  };

  const props = {
    name: "file",
    accept: ".csv",
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        toast.error(`${file.name} is not a csv file`);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          toast.success(`${file.name} file uploaded successfully.`);
          parseCsv(reader.result);
        };
        reader.readAsText(file);
      }
      return false;
    },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
      }
      if (status === "done") {
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const parseCsv = (csvContent) => {
    const rows = csvContent.split(/\r\n|\n/);
    const nonEmptyRows = rows.filter((row) => row.trim() !== "");
    const parsedList = nonEmptyRows.slice(1).map((row) => {
      const [prefix, firstName, lastName, email] = row.split(",");
      return { prefix, firstName, lastName, email: email.toLowerCase() };
    });
    setHcpList(parsedList);
  };

  return (
    <Modal animation={false}>
      <div className="w-full h-[700px] overflow-auto p-2 flex flex-col items-center gap-y-3">
        <MdOutlineClose
          className="ml-auto h-5 w-5 text-gray-600 cursor-pointer"
          onClick={handleClose}
        />
        <div className={`p-4 ${lightbgColor} rounded-full`}>
          <IoPersonAddOutline className={`h-7 w-7 ${textColor}`} />
        </div>
        <div className="flex flex-col text-center">
          <h1 className="text-3xl font-semibold">Invite HCPs</h1>
          <p className="subtitle">
            Enter the name and emails of HCPs you’d like to invite.
          </p>
        </div>
        <div className="flex flex-col align-center p-3  text-base w-full">
          <div className="flex items-center gap-1 text-sm mb-2">
            <GoQuestion className="text-blue-500" />
            <a
              href="/others/jawaab_invite_sample.csv"
              download
              className=" text-center text-blue-500"
            >
              Download example CSV file
            </a>
          </div>
          <Dragger {...props} className="w-full">
            <div className="flex flex-row gap-3 items-center justify-center p-3">
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined className="text-sm " />
              </p>
              <p className="text-sm">
                <p className="font-semibold">Click to upload</p> or drag and
                drop a CSV file
              </p>
            </div>
          </Dragger>
        </div>
        <Spin
          tip="Inviting HCPs..."
          size="large"
          fullscreen={false}
          spinning={isLoading}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 40,
              }}
            />
          }
        >
          <div className="flex flex-col p-3 ">
            <div className="flex flex-col gap-4">
              {hcpList.map((hcp, index) => (
                <div
                  className="flex flex-col border border-gray-300 p-4 rounded-lg"
                  key={index}
                >
                  <div className="flex w-full justify-between items-center mb-4">
                    <h2 className="font-semibold text-base">
                      Person {index + 1}
                    </h2>
                    {index > 0 && (
                      <MdDeleteOutline
                        className="w-6 h-6 align-center text-gray-600 cursor-pointer self-center sm:self-auto"
                        onClick={() => handleRemove(index)}
                        placeholder="Remove"
                      />
                    )}
                  </div>

                  <div className="flex flex-row gap-2 w-full">
                    <Select
                      className="w-1/6 outline-none border border-gray-300 rounded-lg h-200 mb-2"
                      style={{ width: "15%", height: "3rem" }}
                      placeholder="Prefix"
                      value={hcp.prefix}
                      onChange={(value) => handlePrefixChange(value, index)}
                    >
                      {[" ", "Dr.", "Mr.", "Mrs.", "Ms.", "Prof.", "Mx."].map(
                        (prefix) => (
                          <Option
                            className="w-full"
                            value={prefix}
                            key={prefix}
                          >
                            {prefix}
                          </Option>
                        )
                      )}
                    </Select>
                    <input
                      className="flex-1 w-1/3 outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
                      placeholder="First Name"
                      value={hcp.firstName}
                      maxLength="50"
                      onChange={(event) => handleFirstNameChange(event, index)}
                    />
                    <input
                      className="flex-1 w-1/3 outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
                      placeholder="Last Name"
                      maxLength="50"
                      value={hcp.lastName}
                      onChange={(event) => handleLastNameChange(event, index)}
                    />
                  </div>
                  <div className="w-full">
                    <input
                      className="flex-1 w-full outline-none border border-gray-300 rounded-lg px-3 py-2 mb-2"
                      type="email"
                      value={hcp.email}
                      placeholder="Email"
                      maxLength="50"
                      onChange={(event) => handleEmailChange(event, index)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <p
              className={`${textColor} cursor-pointer gap-y-4 my-3`}
              onClick={handleAddMore}
            >
              + Add more
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-between gap-y-2 sm:gap-x-2">
              <button
                className="w-full sm:w-1/2 border border-gray-300 font-medium bg-gray-50 py-2 rounded-lg"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={sendInvitation}
                className={`w-full sm:w-1/2 py-2 rounded-lg text-gray-100 font-medium ${bgColor}`}
              >
                Send invitations
              </button>
            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

export default InviteModal;
