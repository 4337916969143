import React, { useEffect, useState } from "react";
import {
  handleRequest,
  checkPersonalizedQuestions,
} from "../../utils/handleRequest";
import { useParams } from "react-router-dom";
import Conversation from "./features/Conversation";
import Idle from "./features/Idle";
import AvatarContext from "../../components/AvatarContext";
import { fetchContent } from "../../content/FetchContent";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-modern.css";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookie";
import FeedbackForm from "./components/FeedbackForm";
import { checkFeedbackForm } from "../../utils/handleRequest";

export const Home = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let firstTime = urlParams.get("first-time");
  // Attempt to parse the URL parameter if it's not null
  if (firstTime !== null) {
    firstTime = JSON.parse(firstTime);
  } else {
    firstTime = getCookie("firstTime");
  }

  const navigate = useNavigate();
  const [conversation, setConversation] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const [requestLoader, setRequestLoader] = useState(false);
  const [question, setQuestion] = useState("");
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [listening, setListening] = useState(false);
  const [response, setResponse] = useState({
    text: "",
    image: [""],
    video: "",
  });
  const [greetingVideo, setGreetingVideo] = useState();
  const [videoSilent, setVideoSilent] = useState(true);
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [showMSL, setShowMSL] = useState(false);
  const [history, setHistory] = useState([]);
  const [followup, setFollowup] = useState(false);
  const [showFollowupQuestion, setShowFollowupQuestion] = useState(false);
  const [loader, setLoader] = useState(true);
  const [personalizedQuest, setPersonalizedQuest] = useState();
  const [assistantQN, setAssistantQN] = useState("");
  const [playing, playVideo] = useState(false);
  const [brand, setBrand] = useState("");
  const [feedbackFormId, setFeedbackFormId] = useState("");
  const { brandName } = useParams(); // Destructure the brand from the URL parameters

  useEffect(() => {
    if (user == null) setLoader(true);
    else {
      setLoader(false);
      setBrand(user.brand);
    }
    if (brandName) {
      setBrand(brandName);
    } else if (user) {
      setBrand(user.brand);
    }
  }, [user, brandName]);

  const getPersonalizedVideo = async () => {
    setLoader(true);

    if (user) {
      const { success, data } = await checkPersonalizedQuestions(
        user.id,
        brand
      );
      if (success) {
        setGreetingVideo(data.video);
        setHistory(data.conversation);
        setPersonalizedQuest(data.personalizedQuestions);
      }
      setLoader(false);
    }
  };

  const welcomeByJonathan = async () => {
    setLoader(true);
    const { success, content } = await fetchContent("homeScreen", brand);
    if (success) {
      const videoElement = content.options.find(
        (item) => item.type === "videoElement"
      );
      setGreetingVideo(videoElement?.loginFirstTimeVideo);
      setLoader(false);
    }
  };

  useEffect(() => {
    const checkLastLoggedIn = async () => {
      const lastLoggedInTime = localStorage.getItem("lastLoggedIn");

      if (lastLoggedInTime) {
        const lastLoggedInDate = new Date(lastLoggedInTime);

        const currentDate = new Date();

        const timeDifference = currentDate - lastLoggedInDate;

        // If difference is 15 minutes or more (900000 milliseconds)
        if (timeDifference >= 1) {
          if (!firstTime || firstTime == null) {
            await getPersonalizedVideo();
          } else {
            welcomeByJonathan();
          }
          localStorage.setItem("lastLoggedIn", currentDate.toISOString());
        }
      } else {
        if (!firstTime || firstTime == null) await getPersonalizedVideo();
        else welcomeByJonathan();

        localStorage.setItem("lastLoggedIn", new Date().toISOString());
      }
    };

    if (user) {
      if (!firstTime || firstTime == null) getPersonalizedVideo();
      else welcomeByJonathan();
    }
  }, [user, brand]);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        // if (/help/i.test(question)) navigate("/help");
        setResponse((prevResponse) => ({ ...prevResponse, video: "" }));
        const { success, data } = await handleRequest(
          question,
          assistantQN,
          user.id,
          brand
        );

        if (success) {
          setPersonalizedQuest(null);
          setResponse(data);
          setAssistantQN(data.followup_question);

          if (assistantQN !== "") {
            setDescriptionTitle(
              data.proper_question.length > 80
                ? data.proper_question.substring(0, 80) + "..."
                : data.proper_question
            );
          } else {
            setDescriptionTitle(
              question.length > 80
                ? question.substring(0, 80) + "..."
                : question
            );
          }

          setShowMSL(false);
          if (data.short_answer === "I am sorry, I am not able to find that.") {
            setDescriptionTitle(question);

            setShowMSL(true);
          }
          setQuestion("");

          setConversation(true);
        } else {
          toast.error("something went wrong");
          throw new Error("Error in fetching data");
        }
      } catch (error) {
        toast.error("something went wrong");
      } finally {
        setRequestLoader(false);
      }
    };

    if (requestLoader) {
      sendRequest();
    }
  }, [requestLoader]);

  useEffect(() => {
    if (user && !conversation) {
      checkFeedbackForm(user.id, user.email, user.name, user.brand).then(
        (resp) => {
          if (resp?.success) {
            setFeedbackModal(resp?.data?.showFeedbackForm);
            setFeedbackFormId(resp?.data?.formId);
          }
        }
      );
    }
  }, [user, conversation]);

  return (
    <div>
      <AvatarContext.Provider
        value={{
          conversation,
          setConversation,
          requestLoader,
          setRequestLoader,
          question,
          setQuestion,
          response,
          setResponse,
          videoSilent,
          setVideoSilent,
          descriptionTitle,
          listening,
          setListening,
          showMSL,
          setShowMSL,
          greetingVideo,
          setGreetingVideo,
          history,
          setHistory,
          followup,
          setFollowup,
          showFollowupQuestion,
          setShowFollowupQuestion,
          loader,
          setLoader,
          personalizedQuest,
          setPersonalizedQuest,
          assistantQN,
          setAssistantQN,
          playing,
          playVideo,
          brand,
        }}
      >
        <FeedbackForm
          formId={feedbackFormId}
          open={feedbackModal}
          setOpen={setFeedbackModal}
        />
        {conversation ? <Conversation /> : <Idle />}
      </AvatarContext.Provider>
    </div>
  );
};

export default Home;
