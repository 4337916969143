import { useState } from "react";

const useAvatarSelection = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const handleCardClick = (name) => {
    setSelectedAvatar(name);
  };

  return { selectedAvatar, handleCardClick };
};

export default useAvatarSelection;
