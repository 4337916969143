import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Dashboard from "./Dashboard";
import DoctorProfile from "./DoctorProfile";
import Sidebar from "../components/Sidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isExpired } from "react-jwt";
import { getAdminDetails } from "../redux/actions/auth";
import { getCookie } from "../utils/cookie";
import log from "../utils/log";

const Index = () => {
  const location = useLocation();
  const { admin } = useSelector((state) => state.auth);
  const [adminDetails, setAdminDetails] = useState({
    name: "",
    brand: {
      brandName: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarClosingStatus, setSidebarClosingStatus] = useState(true);
  const params = useParams();

  const fetchAdminDetails = async () => {
    try {
      const { success, data } = await getAdminDetails();
      if (success) {
        dispatch({
          type: "SET_ADMIN_DETAILS",
          payload: data.data,
        });
        setAdminDetails(data.data);
      }
    } catch (error) {
      log("error", "Error fetching admin details", error);
    }
  };

  useEffect(() => {
    if (!admin) {
      fetchAdminDetails();
    } else {
      setAdminDetails(admin);
      if (admin?.brand?.brandName !== params?.brand) {
        navigate(`/pharma/unauthorized`, {
          state: { brandName: params?.brand },
        });
        return;
      }
    }

    if (location.pathname.startsWith("/pharma")) {
      const auth = getCookie("brandAccessToken");
      if (isExpired(auth)) {
        navigate(`/pharma/${params?.brand}/signin`);
      } else {
        navigate(`/pharma/${params?.brand}/dashboard`);
      }

      dispatch({
        type: "CHANGE_COLOR",
        payload: {
          textColor: "text-primary2",
          bgColor: "bg-primary2",
          lightbgColor: "bg-primary-light2",
          companyName: "Jawaab",
        },
      });
    }
  }, [admin, params?.brand]);

  return (
    <div className="flex">
      <div
        id="sidebar"
        className="invisible sm:visible transition-all duration-150 ease-in-out"
      >
        <Sidebar
          setSidebarClosingStatus={setSidebarClosingStatus}
          sidebarClosingStatus={sidebarClosingStatus}
          admin={adminDetails}
        />
      </div>
      <div
        className={`${
          sidebarClosingStatus ? "sm:ml-[240px] w-full" : "sm:ml-[80px] w-full"
        }`}
      >
        {location.pathname.endsWith("/dashboard") && (
          <Dashboard
            setSidebarClosingStatus={setSidebarClosingStatus}
            admin={adminDetails}
          />
        )}
        {location.pathname.endsWith("/doctor-profile") && (
          <DoctorProfile setSidebarClosingStatus={setSidebarClosingStatus} />
        )}
      </div>
    </div>
  );
};

export default connect(null, null)(Index);
