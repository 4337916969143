import instance from "./AxiosInstance/unAuthenticated";
import adminInstance from "./AxiosInstance/pharmaAuthenticated";
import { toast } from "sonner";
import { setCookie } from "../../utils/cookie";

export const handleSignIn = async ({ email, password, brand }) => {
  try {
    const { data, status, message } = await instance.post("/brand/signin", {
      email: email.toLowerCase(),
      password: password,
      brandName: brand,
    });

    if (status === 200) {
      setCookie("brandAccessToken", data.accessToken, data.accessTokenExpiry);
      setCookie(
        "brandRefreshToken",
        data.refreshToken,
        data.refreshTokenExpiry
      );
      return { success: true, data: data };
    } else {
      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// currently not in use but can be used in future
export const handleSignUp = async ({ email, password, name }) => {
  try {
    const { data, status } = await instance.post("/user/signup", {
      email: email,
      password: password,
      name: name,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Signed up successfully!");
      return true;
    }
  } catch (error) {
    toast.error("Something went wrong!");
    return false;
  }
};

export const getAdminDetails = async () => {
  try {
    const { data, status } = await adminInstance.get("/brand/admin-details");

    if (status === 200) {
     
      return { success: true, data: data };
    }
  } catch (error) {
    toast.error("Error in getting user details");
  } finally {
  }
};

export const getDashboardUserMetrics = async (
  period,
  brandId,
  metricName = "",
) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/user", {
      brandId: brandId,
      period: period,
      metricName: metricName,
    });
    if (status === 200) {
  
      return { success: true, data: data };
    }
  } catch (error) {

    toast.error("Error in getting user details");
    return { success: false, data: error.response.data };
  } finally {
  }
};


export const getDashboardSessionMetrics = async (
  period,
  brandId,
  metricName = "",
  userId = null
) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/session", {
      brandId: brandId,
      period: period,
      metricName: metricName,
      userId: userId,
    });
    if (status === 200) {
      
      return { success: true, data: data };
    }
  } catch (error) {

    toast.error("Error in getting user details");
    return { success: false, data: error.response.data };
  } finally {
  }
};

export const getDashboardConversationMetrics = async (
  period,
  brandId,
  metricName = "",
  userId = null
) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/conversation", {
      brandId: brandId,
      period: period,
      metricName: metricName,
      userId: userId,
    });
    if (status === 200) {
      
      return { success: true, data: data };
    }
  } catch (error) {

    toast.error("Error in getting user details");
    return { success: false, data: error.response.data };
  } finally {
  }
};

export const getDashboardUserDetailsMetrics = async (brandId) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/user", {
      brandId: brandId,
      metricName: "User Details",
    });

    if (status === 200) {
     
      return { success: true, data: data };
    }
  } catch (error) {
    toast.error("Error in getting user details");
  } finally {
  }
};

export const getDashboardFAQ = async (brandId) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/conversation", {
      brandId: brandId,
      metricName: "Frequently Asked Questions",
    });

    if (status === 200) {
      return { success: true, data: data };
    }
  } catch (error) {
    toast.error("Error in getting FAQ");
  }
};

export const getQuickStat = async (brandId) => {
  try {
    const { data, status } = await adminInstance.post("/metrics/quick-stat", {
      brandId: brandId,
    });

    if (status === 200) {
      return { success: true, data: data };
    }
  } catch (error) {
    toast.error("Error in getting quick stat");
  }
};